import React, { useEffect, useState } from "react";
import axios from "axios";
import Parser from "html-react-parser";

interface Faq {
  pk: number;
  category_pk: number;
  title: string;
  content: string;
}

interface category {
  pk: number;
  name: string;
}

interface Pagenation {
  total: number;
  limit: number;
  page: number;
  prev: number | boolean;
  next: number | boolean;
  setPage: any;
  setPrev: any;
  setNext: any;
}

const MakeList = (props: any) => {
  return (
    <dl>
      <dt>
        <strong>Q</strong>
        {props.title}
      </dt>
      <dd style={{ display: "none" }}>
        <strong>A</strong>
        <div
          style={{
            width: "50%",
            margin: "0 auto",
          }}
        >
          {Parser(props.content)}
        </div>
      </dd>
    </dl>
  );
};

const PageNation = (prop: Pagenation) => {
  const numPage = Math.ceil(prop.total / prop.limit);

  return (
    <>
      <div className="pagingWrap" id="pagingWrap">
        <div className="paging">
          <p className="prev">
            {prop.prev ? (
              <a
                href="#!"
                onClick={() => {
                  prop.setPage(prop.page - 1);
                  prop.setPrev(prop.page - 2 > 0);
                  prop.setNext(prop.page > 0);
                }}
              >
                <img src="/wallet/img/paging_arow.png" alt="" />
              </a>
            ) : (
              <img src="/wallet/img/paging_arow.png" alt="" />
            )}
          </p>
          <ul>
            {Array(numPage)
              .fill(0)
              .map((_, i) => (
                <a
                  href="#!"
                  key={i + 1}
                  onClick={() => {
                    prop.setPage(i + 1);
                    prop.setPrev(i > 0);
                    prop.setNext(i + 1 < numPage);
                  }}
                >
                  <li className={prop.page === i + 1 ? "on" : ""}>{i + 1}</li>
                </a>
              ))}
          </ul>
          <p className="next">
            {prop.next ? (
              <a
                href="#!"
                onClick={() => {
                  prop.setPrev(prop.page - 1 > 0);
                  prop.setPage(prop.page + 1);
                  prop.setNext(prop.page + 1 < numPage);
                }}
              >
                <img src="/wallet/img/paging_arow.png" alt="" />
              </a>
            ) : (
              <img src="/wallet/img/paging_arow.png" alt="" />
            )}
          </p>
        </div>
      </div>
    </>
  );
};

const InfoList = (props: any) => {
  const [faqs, setFaqs] = useState([]);
  const limit = 5;
  const [page, setPage] = useState(1);
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [category, setCategory] = useState([]);
  const [selectValue, setSelectValue] = useState("0");
  const offset = (page - 1) * limit;

  const locale = props.locale === "en" ? "/en" : "";

  useEffect(() => {
    const ax = async () => {
      const resAx = await axios.get(
        "https://version.adappterwallet.io:20443/info" +
          locale +
          "?category_pk=" +
          selectValue
      );
      const resData = resAx.data;
      setFaqs(resData.faq);
      setPrev(false);
      setPage(1);
      setNext(resData.next);
      setCategory(resData.category);
    };
    ax();
  }, [selectValue]);

  const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectValue(e.target.value);
  };

  const resList = faqs
    .slice(offset, offset + limit)
    .map((it: Faq) => (
      <MakeList key={it.pk} pk={it.pk} title={it.title} content={it.content} />
    ));

  const categoryList = category.map((it: category) => {
    return (
      <option key={it.pk} value={it.pk}>
        {it.name}
      </option>
    );
  });

  return (
    <>
      <div className="sContTit">
        {props.locale === "en" ? (
          <h4>Guide</h4>
        ) : (
          <>
            <h5>Guide</h5>
            <h4>이용안내</h4>
          </>
        )}
        <p>
          <select id="category" value={selectValue} onChange={onChangeSelect}>
            <option value="0">{props.locale === "en" ? "ALL" : "전체"}</option>
            {categoryList}
          </select>
        </p>
      </div>
      <div className="infoQna" id="faqWrap">
        {resList}
      </div>
      <PageNation
        total={faqs.length}
        limit={limit}
        page={page}
        prev={prev}
        next={next}
        setPage={setPage}
        setPrev={setPrev}
        setNext={setNext}
      />
    </>
  );
};

export default InfoList;
