import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

const Headers = () => {
  const location = useLocation();
  const path = location.pathname;
  const query = location.search;

  let locale = "ko";
  if (location.pathname.endsWith("/en") || location.pathname.endsWith("/en/")) {
    locale = "en";
  } else {
    locale = "ko";
  }

  useEffect(() => {
    if (locale === "en") {
      $("#lang_kor_h").attr(
        "href",
        path.endsWith("/")
          ? path.replace("/en/", "") + query
          : path.replace("/en", "") + query
      );
      $("#lang_eng_h").attr("href", path + query);
    } else {
      $("#lang_kor_h").attr("href", path + query);
      $("#lang_eng_h").attr(
        "href",
        path.endsWith("/") ? path + "en" + query : path + "/en" + query
      );
    }

    function footLangClosed() {
      $(".footLang li").eq(1).slideUp("fast");

      $(".footLang li").removeClass("up");
    }

    $(".lang li")
      .eq(0)
      .click(function (e) {
        e.preventDefault();

        $(".lang li").eq(1).slideToggle("fast");

        $(".lang li").toggleClass("up");
      });

    $("header").click(function () {
      footLangClosed();
    });

    // mbGNBMenu

    $(".mbMenuOpen").click(function () {
      mbMenuOpen();
    });

    $(".mbMenuClose").click(function () {
      mbMenuClose();
    });

    $(".mbGnbWrapBg").click(function () {
      mbMenuClose();
    });

    function mbMenuOpen() {
      $(".mbGnbWrap").slideDown();

      $(".mbMenuOpen").fadeOut("fast");

      $(".mbMenuClose").fadeIn("fast");

      $(".mbGnbWrapBg").show();

      $("body")
        .addClass("scrollDisable")
        .on("scroll touchmove mousewheel", function (e) {
          e.preventDefault();
        });
    }

    function mbMenuClose() {
      $(".mbGnbWrapBg").hide();

      $(".mbMenuClose").fadeOut("fast");

      $(".mbMenuOpen").fadeIn("fast");

      $(".mbGnbWrap").slideUp();

      $("body").removeClass("scrollDisable").off("scroll touchmove mousewheel");
    }
  }, []);

  return locale === "en" ? (
    <>
      <header>
        <title>Adappter Wallet</title>
        <div className="headWrap">
          <h1>
            <a href="/en">
              <img
                src="/wallet/img/logo_wallet_2x.png"
                alt="로고 이미지"
                className="logo2x"
              />
            </a>
          </h1>
          <p className="mbMenu tb_mb">
            <img src="/wallet/img/menu_mb.png" alt="" className="mbMenuOpen" />
            <img
              src="/wallet/img/menu_mb_close.png"
              alt=""
              className="mbMenuClose"
            />
          </p>
          <ul className="download">
            <li>
              <a
                href="https://apps.apple.com/us/app/adappter-wallet/id1629560173"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/wallet/img/icon_appleStore.png" alt="" />
              </a>
            </li>
            <li className="icon_googlePlay">
              <a
                href="https://play.google.com/store/apps/details?id=com.sinest.adappterwallet"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/wallet/img/icon_googlePlay.png" alt="" />
              </a>
            </li>
            <li className="icon_android">
              <a
                href="https://adappter-wallet.s3.ap-northeast-2.amazonaws.com/AdappterWallet.apk"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/wallet/img/icon_android.png" alt="" />
              </a>
            </li>
          </ul>
          <div className="mbGnbWrapBg .tb_mb"></div>
          <div className="mbGnbWrap">
            <ul className="gnb">
              <li>
                <a href="/en">Introduction</a>
              </li>
              <li>
                <a href="/noti/en">Notice</a>
              </li>
              <li>
                <a href="/info/en">Guide</a>
              </li>
              <li>
                {/* TODO _ 정리해야함.
                <a href="/inq/en">Contact Us</a>
                 */}
                <a
                  href="mailto:help@adappterwallet.io?subject=Contact Us About Using the Adapter Wallet&body=category(Wallet, Transfer, Account, App use, Etc) : %0D%0A%0D%0ADescription : "
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <div className="langWrap">
              <ul className="lang">
                <li>
                  <a id="lang_eng_h" href="/en">
                    English
                  </a>
                </li>
                <li>
                  <a id="lang_kor_h" href="/">
                    한국어
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  ) : (
    <>
      <header>
        <title>어댑터 월렛</title>
        <div className="headWrap">
          <h1>
            <a href="/">
              <img
                src="/wallet/img/logo_wallet_2x.png"
                alt="로고 이미지"
                className="logo2x"
              />
            </a>
          </h1>
          <p className="mbMenu tb_mb">
            <img src="/wallet/img/menu_mb.png" alt="" className="mbMenuOpen" />
            <img
              src="/wallet/img/menu_mb_close.png"
              alt=""
              className="mbMenuClose"
            />
          </p>
          <ul className="download">
            <li>
              <a
                href="https://apps.apple.com/us/app/adappter-wallet/id1629560173"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/wallet/img/icon_appleStore.png" alt="" />
              </a>
            </li>
            <li className="icon_googlePlay">
              <a
                href="https://play.google.com/store/apps/details?id=com.sinest.adappterwallet"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/wallet/img/icon_googlePlay.png" alt="" />
              </a>
            </li>
            <li className="icon_android">
              <a
                href="https://adappter-wallet.s3.ap-northeast-2.amazonaws.com/AdappterWallet.apk"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/wallet/img/icon_android.png" alt="" />
              </a>
            </li>
          </ul>
          <div className="mbGnbWrapBg .tb_mb"></div>
          <div className="mbGnbWrap">
            <ul className="gnb">
              <li>
                <a href="/">월렛소개</a>
              </li>
              <li>
                <a href="/noti/">공지사항</a>
              </li>
              <li>
                <a href="/info/">이용안내</a>
              </li>
              <li>
                {/* TODO _ 정리해야함.
                <a href="/inq">문의하기</a>
                 */}
                <a
                  href="mailto:help@adappterwallet.io?subject=어댑터 월렛 사용 문의&body=문의유형(지갑, 전송, 계정, 앱이용, 기타) : %0D%0A%0D%0A문의내용 : "
                  target="_blank"
                  rel="noreferrer"
                >
                  문의하기
                </a>
              </li>
            </ul>
            <div className="langWrap">
              <ul className="lang">
                <li>
                  <a id="lang_kor_h" href="/">
                    한국어
                  </a>
                </li>
                <li>
                  <a id="lang_eng_h" href="/en">
                    English
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Headers;
