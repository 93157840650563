import React from "react";
import { useLocation } from "react-router-dom";

const Term = (props: any) => {
  const location = useLocation();

  let locale = "ko";

  if (location.pathname.endsWith("/en") || location.pathname.endsWith("/en/")) {
    locale = "en";
  } else {
    locale = "ko";
  }

  return locale === "en" ? (
    <>
      <div className="content policy">
      <h2>[Terms of Service for "Adapter Wallet"]</h2>
      <pre>
        <h3>1.General Provisions</h3>
        {`
Article 1 (Purpose)
The purpose of this Agreement is to prescribe the rights, obligations and responsibilities of the Company and its users and other necessary matters in connection with the use of the Adapter Wallet service provided by Sinest ("Company").


Article 2 (Definitions)
The definitions of terms used in these Terms and Conditions are as follows, and the interpretation of terms not defined is as prescribed in the relevant statutes and separate guidance within the service application.
Paragraph 1. The term "members" means users who agree to the terms and conditions of service provided by the company and use the scope of service provided by the company.
Paragraph 2. The term "non-member" means a user who uses the scope of the service without a separate subscription procedure.
Paragraph 3. The term "service" means a service operated by the company and any service provided by the company to the user through additional development, etc.
Paragraph 4. The term "virtual asset" means the object of the service as information that exists electronically in the blockchain such as Bitcoin and Ethereum that can be transmitted from the service provided by the company.
Paragraph 5. The term 'wallet' is a set of blockchain addresses required for services provided by the company. Users can create and manage wallets for each network, and registered wallets are used to track transactions and transfer virtual assets.
Paragraph 6. The term "password" means a combination of letters and numbers approved by the company by the member in order to confirm the identity of the member and protect the member information.
Paragraph 7. The term "PIN number" means a six-digit number set by a member to confirm the identity of the user before withdrawing the virtual asset. Depending on the user's settings, it is also used in the app execution stage.
Paragraph 8. The term "Import Wallet" means the user's restoration without any intervention in the wallet and virtual assets owned by the user when the user installs a new service due to the replacement or loss of the mobile phone (other reasons for installation).
Paragraph 9. The term 'Deposit' and 'withdrawal' refer to any act of transferring assets from a wallet provided by the service to another wallet.
Section 10. The term 'Private words' means a combination of 12 English words used to import a user's wallet. It is provided in encrypted form to allow users to access and manage their virtual assets. The generated Private words can only be checked by the user who created it, and the company does not store the information of the generated Private words. You are responsible for the loss of the Private words.


Article 3 (Effect/Change of Terms, etc.)
Paragraph 1. The company publishes the contents of these terms and conditions within the service or through a link screen so that users can easily understand them.
Paragraph 2. If deemed necessary, the company may amend these terms and conditions to the extent that it does not violate relevant laws such as "Act On The Regulation Of Terms And Conditions," "Act on Promotion of Information and Communication Network Utilization and Information Protection," "Act on Consumer Protection in e-commerce, etc.
Paragraph 3. When the company revises these terms and conditions, the service shall announce the revision details and the date of application from 7 days before the application date of application. However, if the user changes unfavorably, the notice will be announced 30 days before the application date.
Paragraph 4. Users who cannot be notified individually by using the service provided by the company without separate authentication shall be notified through notice of the service operated by the company, app push, etc. However, users who have completed separate email authentication or self-authentication will be notified individually, including announcements and app push. If it is difficult to notify individually due to changes in the user's e-mail or if it is returned more than twice even though the notice is made to the contact information registered by the user, it is considered to have been notified individually by giving a notice under these terms.
Paragraph 5. If the company does not explicitly refuse to express its intention if it does not express its intention to the user by the day before the application date, the user agrees to the revised terms and conditions.
Paragraph 6. If the user does not agree to the revised terms and conditions, the user may express his/her refusal to the company and terminate the service contract by the day before the application date.


Article 4 (Interpretation of Terms and Conditions)
Paragraph 1. The company may have a separate operation policy in addition to these terms and conditions.
Paragraph 2. The matters or interpretations not prescribed in these terms and conditions shall be governed by the operational policy and relevant laws and regulations.

2. Service use contract
Article 5 (Restriction on the establishment and acceptance of service use contracts)
Paragraph 1. The use contract is concluded by a person who intends to become a member by agreeing to the contents of the terms and conditions provided by the company and then completing the subscription according to the procedure set by the company.
Paragraph 2. The company may terminate the use contract if any of the following reasons are found even after the membership.
1) An act of interfering with the company's normal service by causing a load on the company's server by using a service different from its normal use, such as using an automatic access program, etc
2) If the company violates the operational policy disclosed by the notice in the service;
3) In the event that a member's death
4) Where the personal information of a third party is used or illegally used when applying for membership;
5) In the case of threatening the system, such as changing the company's homepage or client program or hacking the company's server without obtaining special authority from the company;
6) Where an act is deemed to be related to a crime or an activity in violation of relevant laws and regulations;
Paragraph 3. The period of establishment of the contract is when the subscription is completed, but if the reason for termination of the contract is confirmed, the contract may be terminated.
Paragraph 4. The company may collect the minimum amount of information necessary for the service use of the members in addition to the items required for membership registration. To this end, the member will faithfully notify the company's inquiry. In this case, the company uses and manages the information of the members to be collected in accordance with these terms and conditions, personal information handling policies, and the Act on Promotion of Information and Communication Network Utilization and Information Protection.


Article 6 (Technical requirements for service use)
Paragraph 1. The following specifications must be met in order to use the services supplied by the company normally. Service availability may be restricted if the user does not meet the required specifications below.
1) Mobile devices (smartphones, tablet PCs)
The service works best with the latest full version of the OS, and the minimum supported OS version information is as follows:
- Android: SDK Version 16, Android Version 4.1 - 4.1.1 or later
- iOS: iOS 11.0 or later


Article 7 (Management of non-member information)
Paragraph 1. The company does not store and manage information on users who use the scope of the service without a separate subscription procedure. Exceptions apply to the information below.
1) If you enter an email address to receive an answer through 'Contact Us'


Article 8 (Management of member information)
Paragraph 1. If the access information (password, PIN number) of the account registered by the member matches that registered by the company, the user shall be regarded as a member without any other verification procedure.
Paragraph 2. The member is responsible for keeping all account information, including account access information, securely so that a third party without his permission does not access the member's account without permission.
Paragraph 3. The member shall immediately notify the company of the loss, theft, or disclosure of the account access information to a third party. The company can immediately stop using the account.
Paragraph 4. The company shall not be liable for damages caused by loss, theft, or disclosure of access information such as a member's account password. However, this is not the case if the company intentionally or by negligence.


Article 9 (Notification to Users)
Paragraph 1. If the company notifies the user, it may be posted on the notice in the service or on the company's website for at least 30 days, or send an app push, unless otherwise stipulated in these terms and conditions.
Paragraph 2. The company may replace the notice in the preceding paragraph by individual notification only if the user provides the company with an e-mail address, electronic memo, message in the service, etc.


Article 10 (Provision of information and publication of advertisements)
Paragraph 1. The company may provide various information deemed necessary during service use by e-mail, etc. with the prior consent of members. However, in the case of a member who has not used the company's service for six months, the company can provide various information by e-mail, etc. within the scope of the Information and Communication Network Utilization Promotion and Information Protection Act. A member may refuse to receive it by e-mail to the company at any time.
Paragraph 2. The company may post various information deemed necessary by the user during the service use through the notice or bulletin board in the service.
Paragraph 3. The Company shall not be liable for any loss or damage caused by the user's participation in the advertiser's promotional activities through advertising or as a result of communication or transaction


Article 11 (Protection of Personal Information)
The company strives to protect members' personal information as prescribed by relevant laws such as the Act on Promotion of Information and Communication Network Utilization and Information Protection, etc. and the Personal Information Protection Act. The relevant laws and company's personal information processing policy apply to the protection and use of personal information. However, the company's personal information processing policy does not apply to screens that are linked to the outside other than those produced and provided by the company.
5. Obligations, Compensation for Damages, etc


Article 12 (Company's Obligations)
Paragraph 1. The company shall comply with the relevant laws and regulations and these terms and conditions, and shall endeavor to provide continuous and stable service.
Paragraph 2. The company shall endeavor to protect personal information (including credit information) so that members can use the service safely, and shall disclose and comply with the personal information handling policy. The company shall not disclose or provide the member's personal information to a third party, except as prescribed in these terms and conditions and the personal information processing policy.
Paragraph 3. If the company deems that the opinions or complaints raised by the users regarding the use of the service are justified, it shall deal with them. For opinions or complaints raised by users, the process and results are delivered to members and users through a bulletin board or e-mail.
4. In order to provide continuous and stable service, the company shall make every effort to repair or repair equipment without delay, such as natural disasters, emergencies, failures or defects that cannot be solved by current technology.


Article 13 (Obligations of Users)
Paragraph 1. The user shall not: In the case of any of the following acts, sanctions may be imposed, including legal measures such as restricting users' use of services or prosecuting investigative agencies, in accordance with these terms and conditions and operating policies announced for each service.
1) unauthorized alteration of information posted by the company
2) Any processing of this Service and any other modification of the Service by reproduction, disassembly or imitation;
3) An act of interfering with the company's normal service by causing a load on the company's server by using a service different from its normal use, such as using an automatic access program, etc
4) Granting access rights to a third party other than the person himself/herself
5) Infringement of intellectual property rights such as copyrights by the company and other third parties
6) an act that damages the reputation of the company and other third parties or interferes with business;
7) Use of services for profit without the consent of the company
8) other illegal or unjust acts
Paragraph 2. The user is responsible for managing the mobile device and should not be allowed to use it by others. The company is not responsible for damages caused by poor management of mobile devices or by accepting use of them to others.


Article 14 (Obligation of Members)
Paragraph 1. A member shall not do the following in addition to the user's obligations. In the case of any of the following acts, sanctions may be imposed, including legal measures such as restricting the use of services by members, deleting accounts, and prosecuting investigative agencies, in accordance with these terms and conditions.
1) Registration of false information when applying for and changing membership or using services
2) Steal information about others
3) Granting of account authority to a third party other than the person himself/herself
Paragraph 2. The member is responsible for managing the account of the member and should not be allowed to use it by others. The company is not responsible for any damage caused by poor management of the account or by accepting the use of it to others.


Article 15 (Provision of Services, etc.)
Paragraph 1. The company provides the following services to users.
1) Creating a new wallet address
2) Verification of virtual assets held, such as ADP, ETH, etc
3) Transfer of held virtual assets such as ADP, ETH, etc
4) Registration and cancellation of virtual assets visible through the service
5) All other services developed by the company or provided to users through partnership contracts with other companies
Paragraph 2. In principle, the service is provided 24 hours a day, 24/7.
Paragraph 3. The company may conduct regular inspections, temporary inspections, and emergency inspections if necessary for the provision of services, and each inspection time shall be as notified on the service delivery screen. In this case, the company notifies the user in advance through the service initial screen or notice board. However, if there is an unavoidable reason that the company cannot notify in advance, it can be notified afterwards. In addition, the use of the service may be partially or entirely restricted during each inspection, and we are not responsible for any damages caused by the service unless it is intentional or negligible by the company.


Article 16 (Change of Service)
Paragraph 1. The company may change the contents, operation, technical matters, etc. of the service in order to provide a stable service.
Paragraph 2. When the company changes the service, it shall notify the change details and application date in advance. However, if there is an unavoidable reason that the company cannot announce in advance, it can be announced afterwards.
Paragraph 3. If the member does not agree to change the service, he/she may express his/her refusal to the company and terminate the contract.


Article 17 (Compensation for Damages)
Paragraph 1. In the event of damage to the user due to the company's responsible reasons, the scope of the company's damages includes ordinary damages prescribed by the Civil Act, and damages caused by special circumstances are liable for compensation only when the company knows or knows the circumstances.
Paragraph 2. In any of the following cases, the user may bear all or part of the responsibility:
1) Where a user intentionally or negligently leaks unique information, service access devices, or possible means and transaction information that can access the wallet to a third party, or causes the user to use the service arbitrarily;
2) Where there is any other intention or negligence of the user in the occurrence of damage;
Paragraph 3. The company shall not be liable for damages caused to the user due to the company's irresponsible reasons.
Paragraph 4. If the information provided to the company is not true, the company may at any time discontinue the service and terminate the contract in whole or in part under this Agreement, and if the damage occurs to the company, it may claim compensation from the member.
Paragraph 5. The user shall compensate the company for all damages caused by intentional or negligent acts such as obstructing the company's service or related system operation.
Paragraph 6. The company may modify, discontinue, or change some or all of the services provided due to the company's policy and operational reasons, and shall not compensate for such changes unless otherwise provided for in the relevant laws and regulations.


Article 18 (Exemption)
Paragraph 1. The company shall not be liable for damages caused to the user or a third party due to the following reasons:
1) Where the service cannot be provided due to a natural disaster or equivalent force majeure;
2) Where a user has lost or leaked important information related to his/her service in order to use the company's services by neglecting to manage such information;
3) Where the service cannot be used due to the failure of the public communication line other than the company's management area;
4) In the case of a failure of communication services, etc. without reasons attributable to the company;
5) A network failure or failure of each digital asset (for example, a bitcoin network) occurs
6) In the case of compliance with administrative dispositions, orders, etc. by government agencies in practice or by law;
Paragraph 2. The company is not obligated to intervene in disputes arising from services between users or between users and third parties, and is not liable to compensate for damages caused by such disputes unless there are reasons attributable to the company.
Paragraph 3. When it is proved that the manager has fulfilled his/her care as follows, he/she shall not be liable for the damage.
1) Appointment and management of information protection officers
2) Information Protection Education
3) Protection measures for control rooms (where computer, communications and security equipment are installed), facilities in the building where control rooms are located, information protection systems (equipment and programs to protect information from leaking, falsifying, damaging, or interfering with the information processing system's normal services) and information processing systems (including digital asset transactions)
4) Establishment of countermeasures for information security incidents
5) Regular inspection of information protection measures and countermeasures against security accidents, etc
6) Other matters prescribed by laws related to information protection;


Article 19 (Jurisdiction of the governing law and jurisdiction)
Paragraph 1. Disputes arising between the company and the user shall be governed by the laws of the Republic of Korea.
Paragraph 2. The court with jurisdiction over the dispute between the company and the user shall be the court that the parties agree to decide, and if no agreement is reached, it shall be the court under the Civil Procedure Act.


Supplementary Provisions
This Service Terms and Conditions will be effective from June 16, 2022.
        `}
      </pre>

      </div>
    </>
  ) : (
    <>
      <div className="content policy">
        <h2>[어댑터월렛 서비스이용약관]</h2>
        <pre>
          <h3>1. 총칙</h3>
          {`
제 1조 (목적)
이 약관은 <시니스트>(이하 “회사”)가 제공하는 <어댑터월렛> 서비스(이하 “서비스”)의 이용과 관련하여 회사와 이용자의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정하는데 그 목적이 있습니다.


제 2조 (정의)
이 약관에서 사용하는 용어의 정의는 다음 각 항과 같으며, 정의되지 않은 용어에 대한 해석은 관계법령 및 서비스 어플리케이션 내 별도 안내에서 정하는 바에 따릅니다.
1항. ‘회원’이라 함은 회사가 제공하는 서비스 약관 및 개인정보처리방침에 동의하고 제공하는 서비스의 범위를 이용하는 이용자를 말합니다.
2항. ‘비회원’이라 함은 별도의 가입절차 없이 서비스의 범위를 이용하는 이용자를 말합니다.
3항. ‘서비스’라 함은 회사가 운영하는 서비스 및 회사가 추가 개발 등을 통해 이용자에게 제공하는 일체의 서비스를 말합니다.
4항. ‘가상자산’이라 함은 회사가 제공하는 서비스에서 전송할 수 있는 비트코인, 이더리움 등 블록체인에서 전자적으로 존재하는 정보로 서비스의 대상물을 말합니다.
5항. ‘지갑’이라 함은 회사가 제공하는 서비스에 필요한 블록체인 주소 집합입니다. 이용자는 각 네트워크 별 지갑을 생성, 관리할 수 있으며 등록된 지갑은 트랜잭션 추적 및 가상자산 전송함에 있어 사용됩니다.
6항. ‘비밀번호’라 함은 회원의 동일성 확인과 회원정보의 보호를 위하여 회원이 회사가 승인한 문자와 숫자의 조합대로 설정한 것을 말합니다.
7항. ‘PIN번호’라 함은 가상 자산을 출금하기 전 이용자의 동일성 확인을 위해 회원이 6자리의 숫자로 설정한 것을 말합니다. 이용자의 설정에 따라 App 실행 단계에도 사용됩니다.
8항. ‘지갑 불러오기’라 함은 이용자가 휴대전화의 교체 혹은 분실(그 외 서비스를 새로 설치해야 할 사유가 있는 경우)등으로 서비스를 새로 설치할 시 이용자가 소유하고 있던 지갑 및 가상자산에 대한 회사의 어떠한 개입도 없이 이용자가 복원하는 것을 말합니다.
9항. ‘입금’ 및 ‘출금’이라 함은 서비스에서 제공하는 지갑에서 다른 지갑으로 자산을 전송하는 일체의 행위를 말합니다.
10항.  ‘니모닉’이라 함은 이용자가 지갑을 복원할 때 사용되는 12개로 이루어진 영단어의 조합을 말합니다. 이용자가 본인의 가상자산에 접근 및 관리할 수 있도록 위해 암호화한 형태로 제공되는 것을 말합니다. 생성된 니모닉은 생성한 이용자만 확인 가능하며, 회사는 생성된 니모닉의 정보를 저장 하지 않습니다. 니모닉을 분실하여 발생되는 책임에 대해서는 이용자 본인이 모두 부담합니다.


제 3조(약관의 효력/변경 등)
1항. 회사는 이 약관의 내용을 이용자가 쉽게 알 수 있도록 서비스 내 또는 연결화면을 통하여 게시합니다.
2항. 회사는 필요하다고 인정하는 경우, “약관의규제에관한법률”, “정보통신망이용촉진및정보보호에관한법률", “콘텐츠산업진흥법”, “전자상거래등에서의소비자보호에관한법률” 등 관련 법령을 위반하지 않는 범위에서 이 약관을 개정할 수 있습니다.
3항. 회사가 이 약관을 개정할 경우에는 개정내용과 적용 일자를 명시하여 서비스에서 적용 일자 7일 전부터 적용 일자 전날까지 공지합니다. 다만, 이용자가 불리한 변경의 경우에는 적용 일자 30일 전부터 공지합니다.
4항. 회사가 제공하는 서비스를 별도의 인증없이 사용하는 개별통지가 불가능한 이용자에게는 회사가 운영하는 서비스의 공지사항, 앱 푸시 등을 통해 통지합니다. 단, 별도의 이메일 인증 또는 본인인증을 완료한 이용자에게는 공지사항, 앱 푸시 외 개별 통지합니다. 이용자의 E-mail 변경 등으로 인하여 개별 통지가 어려운 경우와 이용자가 등록한 연락처로 공지를 하였음에도 2회 이상 반송 등이 된 경우 이 약관에 의한 공지를 함으로써 개별 통지한 것으로 간주합니다.
5항. 회사가 전항에 따라 공지하면서 이용자에게 적용 일자 전날까지 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 이용자가 명시적으로 거부의 의사표시를 하지 아니한 경우 이용자가 개정약관에 동의한 것으로 봅니다.
6항. 이용자는 개정 약관에 동의하지 않는 경우 적용 일자 전날까지 회사에 거부 의사를 표시하고 서비스 이용계약을 해지할 수 있습니다.


제 4조 (약관의 해석)
1항. 회사는 이 약관 외에 별도의 운영정책을 둘 수 있습니다.
2항. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 운영정책, 관련 법령에 따릅니다.

2. 서비스 이용계약
제 5조 (서비스 이용계약의 성립 및 승낙의 제한)
1항. 이용계약은 회원이 되고자 하는 자가 회사가 제공하는 약관의 내용에 대하여 동의를 한 다음 회사가 정한 절차에 따라 가입을 완료함으로써 체결됩니다.
2항. 회사는 가입을 완료한 회원에 대하여 아래 각호에 해당하는 사유가 있는 경우에는 가입 이후에도 아래 각호의 사유가 확인될 경우에는 이용계약을 해지할 수 있습니다.
1) 자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를 이용하여 회사의 서버에 부하를 일으켜 회사의 정상적인 서비스를 방해하는 행위
2) 회사가 서비스 내 공지사항을 통하여 공시하는 운영 정책을 위반하는 경우
3) 회원이 사망했을 경우
4) 회원 가입 신청 시 제3자의 개인정보를 이용 및 불법으로 도용한 경우
5) 회사로부터 특별한 권한을 부여 받지 않고 회사의 홈페이지 또는 클라이언트 프로그램을 변경 또는 회사의 서버를 해킹하는 등의 시스템을 위협하는 행위를 한 경우
6) 범죄와의 결부, 관련 법령 위반 활동으로 판단되는 행위를 한 경우
3항. 이용계약의 성립 시기는 가입을 완료한 시점으로 하나 이용계약 해지 사유가 확인될 경우 이용계약을 해지할 수 있습니다.
4항. 회사는 회원 가입에 필요한 기재사항 이외에 회원의 서비스 이용에 필요한 최소한의 정보를 수집할 수 있습니다. 이를 위해 회사가 문의한 사항에 대해 회원은 성실하게 고지합니다. 회사는 이 경우 수집하는 회원의 정보를 본 약관, 개인정보취급방침 및 「정보통신망의 이용촉진 및 정보보호 등에 관한 법률」이 정한 바에 따라 이용, 관리합니다.


제 6조 (서비스 이용의 기술적 요구 사항)
1항. 회사는 서비스를 공급하면서 다음 각 호의 사양이 충족되어야 정상적인 서비스 이용이 가능하며, 이용자가 아래의 필수 사양을 충족하지 못할 경우 서비스 이용이 제한될 수 있습니다.
<서비스 이용 필수 사양>
1) 모바일 단말기(스마트폰, 태블릿 PC)
서비스는 가장 최신의 정식 버전 OS에서 가장 잘 동작하며, 최소 지원 OS 버전 정보는 다음과 같습니다.
- Android: SDK Version 16, Android Version 4.1 - 4.1.1 이상 지원
- iOS: iOS 11.0 버전 이상


제 7조 (비회원 정보의 관리)
1항. 회사는 함은 별도의 가입절차 없이 서비스의 범위를 이용하는 이용자에 대한 정보를 보관, 관리 하지 않습니다. 단, 아래의 경우의 해당되는 정보는 예외로 합니다.
1) ‘문의하기’를 통해 답변 받을 E-mail 주소를 입력한 경우


제 8조 (회원 정보의 관리)
1항. 회사는 회원이 등록한 계정 접근정보(비밀번호, PIN번호)가 회사에 등록된 것과 일치할 경우에는 별도의 확인절차 없이 이용자를 회원으로 간주합니다.
2항. 회원은 본인의 허가를 받지 않은 제삼자가 회원의 계정에 무단으로 접근하지 않도록, 계정 접근정보를 포함하여 일체의 계정 정보를 안전하게 보관할 책임이 있습니다.
3항. 회원은 계정 접근정보를 분실했거나, 도용 당했거나, 제삼자에게 공개되었음을 인지한 경우 이를 즉시 회사에 통지해야 합니다. 회사는 즉시 계정 이용중단 등의 조치를 할 수 있습니다.
4항. 회원의 계정 비밀번호 등 접근정보가 분실, 도용 혹은 공개되어 회원에게 발생한 손해에 대하여 회사는 책임을 부담하지 아니합니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.


제 9조 (이용자에 대한 통지)
1항. 회사가 이용자에게 통지를 하는 경우 이 약관에 별도의 규정이 없는 한 30일 이상 서비스 내 공지사항 또는 회사 웹페이지 내 공지사항에 게시하거나, 앱 푸시를 발송하는 등의 방법으로 할 수 있습니다.
2항. 이용자가 회사에 전자우편주소, 전자메모, 서비스 내 메시지 등을 제공한 경우에 한하여 회사는 개별 통지의 방법으로 전항 통지에 갈음할 수 있습니다.


제 10조 (정보의 제공 및 광고의 게재)
1항. 회사는 회원의 사전 동의를 얻어 서비스 이용 중 필요하다고 인정되는 다양한 정보를 전자우편 등의 방법으로 제공할 수 있습니다. 다만, 회사의 서비스를 이용한지 6개월이 경과하지 아니한 회원의 경우 회원의 사전동의가 없더라도 회사는 정보통신망이용촉진 및 정보보호에 관한 법률 등에 따른 범위에서 전자우편 등의 방법으로 다양한 정보를 제공할 수 있습니다. 회원은 언제든지 회사에게 전자우편 등을 통하여 수신거절을 할 수 있습니다.
2항. 회사는 이용자가 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 서비스 내 게시판을 통해 게시할 수 있습니다.
3항. 회사는 게재된 광고를 통한 광고주의 판촉활동에 이용자가 참여하거나 교신 또는 거래의 결과로서 발생하는 손실 또는 손해에 대해 책임을 지지 않습니다


제 11조 (개인정보의 보호)
회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법 등 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사가 제작하여 제공한 화면 이외의 외부로 링크된 화면 등에서는 회사의 개인정보처리방침이 적용되지 않습니다.


5. 의무, 손해배상 등
제 12조 (회사의 의무)
1항. 회사는 관련 법령과 이 약관을 준수하며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
2항. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 최대한 노력하여야 하며 개인정보취급방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.
3항. 회사는 서비스이용과 관련하여 이용자로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 이용자가 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 회원과 사용자에게 처리과정 및 결과를 전달합니다.
4항. 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실, 훼손된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.


제 13조 (이용자의 의무)
1항. 이용자는 다음 행위를 하여서는 안 됩니다. 아래 각 호에 해당되는 행위를 할 경우에는 본 약관 및 각 서비스 별로 공지하는 운영정책에 따라 이용자의 서비스 이용을 제한하거나 수사기관의 고발 조치 등 적법한 조치를 포함한 제재를 가할 수 있습니다.
1) 회사가 게시한 정보의 무단 변경
2) 본 서비스에 대한 일체의 가공행위 및 서비스를 복제, 분해 또는 모방 기타 변형하는 행위
3) 자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를 이용하여 회사의 서버에 부하를 일으켜 회사의 정상적인 서비스를 방해하는 행위
4) 본인 아닌 제3자에게 접속권한을 부여하는 행위
5) 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해
6) 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
7) 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위
8) 기타 불법적이거나 부당한 행위
2항. 이용자의 모바일 기기에 관한 관리 책임은 이용자에게 있으며, 이를 타인이 이용하도록 하게 하여서는 안됩니다. 모바일 기기의 관리 부실이나 타인에게 이용을 승낙함으로 인해 발생하는 손해에 대해서 회사는 책임을 지지 않습니다.


제 14조 (회원의 의무)
1항. 회원은 이용자의 의무와 더불어 다음 행위를 하여서는 안 됩니다. 아래 각 호에 해당되는 행위를 할 경우에는 본 약관 및 각 서비스 별로 공지하는 운영정책에 따라 회원의 서비스 이용을 제한하거나 계정의 삭제, 수사기관의 고발 조치 등 적법한 조치를 포함한 제재를 가할 수 있습니다.
1) 회원가입 신청 및 변경, 서비스 이용 시 허위내용의 등록
2) 타인의 정보도용
3) 본인 아닌 제3자에게 계정권한을 부여하는 행위
2항. 회원의 계정에 관한 관리 책임은 회원에게 있으며, 이를 타인이 이용하도록 하게 하여서는 안됩니다. 계정의 관리 부실이나 타인에게 이용을 승낙함으로 인해 발생하는 손해에 대해서 회사는 책임을 지지 않습니다.


제 15조 (서비스의 제공 등)
1항. 회사는 이용자에게 아래와 같은 서비스를 제공합니다.
1) 새로운 지갑 주소의 생성
2) ADP, ETH 등 보유 가상 자산의 확인
3) ADP, ETH 등 보유 가상 자산의 전송
4) 서비스를 통해 보여지는 가상자산의 등록 및 해제
5) 기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 이용자에게 제공하는 일체의 서비스
2항. 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
3항. 회사는 서비스의 제공에 필요한 경우 정기점검, 임시점검, 긴급점검을 실시할 수 있으며 각 점검 시간은 서비스 제공 화면에 공지한 바에 따릅니다. 이 경우 회사는 사전에 서비스 초기 화면이나 공지사항 게시판을 통하여 이용자에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다. 또한 각 점검 시, 서비스 이용에 일부 또는 전체에 대하여 제한이 발생할 수 있으며 회사의 고의 또는 과실이 아닌 한 그로 인해 발생한 손해에 대해서는 책임지지 않습니다.


제 16조 (서비스의 변경)
1항. 회사는 안정적인 서비스 제공을 위하여 서비스의 내용, 운영상, 기술상 사항 등을 변경할 수 있습니다.
2항. 회사는 서비스를 변경할 경우 변경내용과 적용 일자를 명시하여 사전에 공지합니다. 다만, 회사가 사전에 공지할 수 없는 부득이한 사유가 있는 경우 사후에 공지할 수 있습니다.
3항. 회원은 서비스 변경에 동의하지 않을 경우 회사에 거부 의사를 표시하고 이용계약을 해지할 수 있습니다.


제 17조 (손해배상)
1항. 회사의 책임 있는 사유로 인하여 이용자에게 손해가 발생한 경우 회사의 손해배상 범위는 민법에서 정하고 있는 통상손해를 포함하고, 특별한 사정으로 인한 손해는 회사가 그 사정을 알았거나 알 수 있었을 때에 한하여 배상 및 책임이 있습니다.
2항. 다음 각 호의 어느 하나에 해당하는 경우에는 이용자가 그 책임의 전부 또는 일부를 부담할 수 있습니다.
1) 이용자의 고의 또는 과실로 제3자에게 지갑에 접근할 수 있는 고유의 정보, 서비스 접근 기기 또는 가능한 수단 및 거래 정보를 유출하거나 서비스를 임의로 사용하게 한 경우
2) 그 외에 손해 발생에 있어서 이용자의 고의나 과실이 있는 경우
3항. 회사는 회사의 책임 없는 사유로 인하여 이용자에게 발생한 손해에 대하여는 배상책임이 없습니다.
4항. 회원이 회사에 제공한 정보가 사실과 다를 경우, 회사는 언제든지 서비스의 제공을 중단하고 본 약관에 의해 계약 전체 또는 일부를 해지할 수 있으며, 이로 인하여 회사에 손해가 발생한 경우, 회원에게 그 손해의 배상을 청구할 수 있습니다.
5항. 이용자는 회사의 서비스 또는 관련 시스템 운영을 방해하는 등 고의 또는 과실로 법령, 본 약관, 사회질서에 위배하는 행위 등을 통하여 회사에 손해를 발생시킨 경우에는 회사에 발생한 손해를 모두 배상해야 합니다.
6항. 회사는 제공되는 서비스의 일부 또는 전부를 회사의 정책, 운영상의 긴요한 사유로 수정, 중단, 변경할 수 있으며, 이에 대하여 관련 법령에 별도 규정이 있지 아니하는 한 별도의 보상을 하지 않습니다.


제 18조 (면책)
1항. 회사는 다음 각 호의 사유로 인하여 이용자 또는 제3자에게 발생한 손해에 대하여는 그 책임을 지지 아니합니다.
1) 천재지변 또는 이에 준하는 불가항력으로 인해 서비스를 제공할 수 없는 경우
2) 이용자가 회사의 서비스를 이용하기위해 사용중인 자신의 서비스 관련 중요정보의 관리를 소홀히 하여 분실 및 유출한 경우
3) 회사의 관리영역이 아닌 공중통신선로의 장애로 서비스 이용이 불가능한 경우
4) 기타 회사의 귀책사유가 없는 통신 서비스 등의 장애로 인한 경우
5) 각 디지털 자산의 네트워크(예 : 비트코인 네트워크) 장애 또는 오류가 발생한 경우
6) 정부 기관의 사실상 또는 법률상 행정처분 및 명령 등에 대한 준수로 인한 경우
2항. 회사는 이용자 상호간 또는 이용자와 제3자 상호간에 서비스와 관련하여 발생한 분쟁에 대하여 개입할 의무가 없으며, 회사에 귀책사유가 없는 한 이로 인하여 발생한 손해를 배상할 책임이 없습니다.
3항. 아래와 같이 관리자의 주의를 다하였음을 입증한 때에는 그 손해에 대하여 책임을 지지 아니합니다.
1) 정보보호 책임자 선임 및 관리
2) 정보보호 교육
3) 통제실(회사의 중요 정보를 저장하고 있는 전산장비, 통신 및 보안장비가 설치된 장소), 통제실이 위치한 건물 내 시설, 정보 보호 시스템(정보처리시스템 내 정보를 유출, 위변조, 훼손하거나 정보처리시스템의 정상적인 서비스를 방해하는 행위로부터 정보 등을 보호하기 위한 장비 및 프로그램) 및 정보처리시스템(디지털 자산 거래를 포함하여 정보기술 부문에 사용되는 하드웨어, 소프트웨어 및 관련 장비)에 대한 보호 대책
4) 정보보안사고 대응 방안 수립
5) 정보보호 대책 및 보안사고 대응방안 등에 대한 정기적인 점검
6) 기타 정보보호 관련 법률에서 준수하도록 정하는 사항


제 19조 (준거법 및 재판관할)
1항. 회사와 이용자간 발생한 분쟁에 대하여는 대한민국 법을 준거법으로 합니다.
2항. 회사와 이용자간 발생한 분쟁에 관한 소송의 관할법원은 당사자들이 합의하여 결정하는 법원으로 하며 합의가 이루어지지 않을 때에는 민사소송법에 의한 법원으로 합니다.


부칙
본 서비스이용약관은 2022년 6월 16일부터 적용됩니다.          
          `}
        </pre>
      </div>
    </>
  );
};

export default Term;
