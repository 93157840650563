import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

const Footer = () => {
  const location = useLocation();
  const path = location.pathname;
  const query = location.search;

  let locale = "ko";
  if (location.pathname.endsWith("/en") || location.pathname.endsWith("/en/")) {
    locale = "en";
  } else {
    locale = "ko";
  }

  useEffect(() => {
    if (locale === "en") {
      $("#lang_kor_f").attr(
        "href",
        path.endsWith("/")
          ? path.replace("/en/", "") + query
          : path.replace("/en", "") + query
      );
      $("#lang_eng_f").attr("href", path + query);
    } else {
      $("#lang_kor_f").attr("href", path + query);
      $("#lang_eng_f").attr(
        "href",
        path.endsWith("/") ? path + "en" + query : path + "/en" + query
      );
    }

    function langClosed() {
      $(".lang li").eq(1).slideUp("fast");

      $(".lang li").removeClass("up");
    }

    $(".footLang li")
      .eq(0)
      .click(function (e) {
        e.preventDefault();

        $(".footLang li").eq(1).slideToggle("fast");

        $(".footLang li").toggleClass("up");
      });
    $("footer").click(function () {
      langClosed();
    });
  }, []);

  return locale === "en" ? (
    <>
      <footer>
        <div className="footIn">
          <h2>
            <a href="/">
              <img src="/wallet/img/logo_wallet_white_2x.png" alt="푸터 로고" />
            </a>
          </h2>
          <ul className="poli clear">
            <li>
              <a href="/policy/en/">Privacy policy</a>
            </li>
            <li>
              <a href="/term/en/">Terms and Conditions of Service</a>
            </li>
          </ul>
          <div className="footTxt">
            <p>SINEST Corporation</p>
            <p>Copyright by SINEST All rights reserved.</p>
          </div>
          <ul className="footLang pc_tb">
            <li>
              <a id="lang_eng_f" href="/en">
                English
              </a>
            </li>
            <li>
              <a id="lang_kor_f" href="/">
                한국어
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  ) : (
    <>
      <footer>
        <div className="footIn">
          <h2>
            <a href="/">
              <img src="/wallet/img/logo_wallet_white_2x.png" alt="푸터 로고" />
            </a>
          </h2>
          <ul className="poli clear">
            <li>
              <a href="/policy/">개인정보처리방침</a>
            </li>
            <li>
              <a href="/term/">서비스이용약관</a>
            </li>
          </ul>
          <div className="footTxt">
            <p>SINEST Corporation</p>
            <p>Copyright by SINEST All rights reserved.</p>
          </div>
          <ul className="footLang pc_tb">
            <li>
              <a id="lang_kor_f" href="/">
                한국어
              </a>
            </li>
            <li>
              <a id="lang_eng_f" href="/en">
                English
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
