import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

import InfoList from "../List/InfoList";
import BoardTop from "../Components/board_top";

const Info = () => {
  const location = useLocation();

  let locale = "ko";
  if (location.pathname.endsWith("/en") || location.pathname.endsWith("/en/")) {
    locale = "en";
  } else {
    locale = "ko";
  }

  const image = "/wallet/img/sub_v_info_2x.png";

  useEffect(() => {
    $(".infoQna dd").hide();

    $(document).on("click", ".infoQna dt", function () {
      $(this).parent().siblings().children("dd").slideUp();
      $(this).siblings("dd").slideToggle();
      $(this).parent().siblings().children("dt").removeClass("on");
      $(this).toggleClass("on");
    });
  }, []);

  return locale === "en" ? (
    <>
      <div className="sub">
        <BoardTop
          locale={locale}
          class={"subVsImg infoImg infoImg2x"}
          image={image}
        />
        <div className="info subCont">
          <div className="subContIn">
            <InfoList locale={locale} />
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="sub">
        <BoardTop
          locale={locale}
          class={"subVsImg infoImg infoImg2x"}
          image={image}
        />

        <div className="info subCont">
          <div className="subContIn">
            <InfoList locale={locale} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
