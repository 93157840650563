import axios from "axios";
import Parser from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BoardTop from "../Components/board_top";

const NoticeView = (props: any) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const [time, setTime] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const pk = new URLSearchParams(location.search).get("pk");
  const cur = new URLSearchParams(location.search).get("cur");

  let locale = "ko";

  if (location.pathname.endsWith("/en") || location.pathname.endsWith("/en/")) {
    locale = "en";
  } else {
    locale = "ko";
  }

  const localeEndpoint = locale === "en" ? "/en" : "";

  useEffect(() => {
    const link = `https://version.adappterwallet.io:20443/noti_detail${localeEndpoint}?pk=${pk}&cur=${cur}`;

    const ax = async () => {
      const resAx = await axios.get(link);
      const resData = resAx.data;

      if (resData.data.length) {
        const resDt = resData.data;
        setTitle(resDt[0].title);
        setContent(resDt[0].content);
        setImage(resDt[0].image ? resDt[0].image : "");
        setTime(resDt[0].time);
      }
    };
    ax();
  }, []);

  return locale === "en" ? (
    <>
      <div className="sub">
        <BoardTop
          locale={locale}
          class={"subVsImg notiImg notiImg2x"}
          image={"/wallet/img/sub_v_noti_2x.png"}
        />
        <div className="noti subCont">
          <div className="subContIn">
            <div className="sContTit">
              <h4>Notice</h4>
            </div>

            <div className="notiList notiView">
              <ul>
                <li>{title}</li>
                <p>{time}</p>
              </ul>

              <div className="notiViewCont">
                {image !== "" ? (
                  <p className="notiViewImg">
                    <img src={image} alt="" />
                  </p>
                ) : (
                  <></>
                )}

                <p className="notiViewTxt">
                  <>{Parser(content)}</>
                </p>
              </div>
            </div>

            <div className="inqBtnWrap btnGoList">
              <button
                type="button"
                onClick={() => navigate(`/noti/en?page=${cur}`)}
              >
                List
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="sub">
        <BoardTop
          locale={locale}
          class={"subVsImg notiImg notiImg2x"}
          image={"/wallet/img/sub_v_noti_2x.png"}
        />
        <div className="noti subCont">
          <div className="subContIn">
            <div className="sContTit">
              <h5>Notice</h5>

              <h4>공지사항</h4>
            </div>

            <div className="notiList notiView">
              <ul>
                <li>{title}</li>
                <p>{time}</p>
              </ul>

              <div className="notiViewCont">
                {image !== "" ? (
                  <p className="notiViewImg">
                    <img src={image} alt="" />
                  </p>
                ) : (
                  <></>
                )}

                <p className="notiViewTxt">
                  <>{Parser(content)}</>
                </p>
              </div>
            </div>

            <div className="inqBtnWrap btnGoList">
              <button
                type="button"
                onClick={() => navigate(`/noti?page=${cur}`)}
              >
                목록보기
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeView;
