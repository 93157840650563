import React from "react";

const BoardTop = (prop: any) => {
  return prop.locale === "en" ? (
    <>
      <div className="subVs">
        <div className="subVsIn">
          <div className="subVsTit">
            <p>The Begin of the Cryptoasset management </p>

            <h3>
              Adappter <strong>Wallet</strong>
            </h3>

            <ul className="btnDownload">
              <li>
                <a
                  href="https://apps.apple.com/us/app/adappter-wallet/id1629560173"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_appleStore_2x_en.png"
                    alt="Googleplay"
                    className="dwnlodAppleStore"
                  />
                </a>
              </li>

              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sinest.adappterwallet"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_googlePlay_2x_en.png"
                    alt="Appstore"
                    className="dwnlodGooglePlay"
                  />
                </a>
              </li>

              <li>
                <a
                  href="https://adappter-wallet.s3.ap-northeast-2.amazonaws.com/AdappterWallet.apk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_android_2x.png"
                    alt="Android"
                    className="dwnlodAndroid"
                  />
                </a>
              </li>
            </ul>
          </div>

          <p className={prop.class}>
            <img src={prop.image} alt="" />
          </p>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="subVs">
        <div className="subVsIn">
          <div className="subVsTit">
            <p>가상자산 관리의 시작</p>

            <h3>
              어댑터 <strong>월렛</strong>
            </h3>

            <ul className="btnDownload">
              <li>
                <a
                  href="https://apps.apple.com/us/app/adappter-wallet/id1629560173"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_appleStore_2x.png"
                    alt="Googleplay"
                    className="dwnlodAppleStore"
                  />
                </a>
              </li>

              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sinest.adappterwallet"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_googlePlay_2x.png"
                    alt="Appstore"
                    className="dwnlodGooglePlay"
                  />
                </a>
              </li>

              <li>
                <a
                  href="https://adappter-wallet.s3.ap-northeast-2.amazonaws.com/AdappterWallet.apk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_android_2x.png"
                    alt="Android"
                    className="dwnlodAndroid"
                  />
                </a>
              </li>
            </ul>
          </div>
          <p className={prop.class}>
            <img src={prop.image} alt="" />
          </p>
        </div>
      </div>
    </>
  );
};

export default BoardTop;
