import React from "react";
import { useLocation } from "react-router-dom";

import { ArchivePolicy } from "./Archive/policy";

const Policy = (props: any) => {
  const location = useLocation();

  let locale = "ko";

  if (location.pathname.endsWith("/en") || location.pathname.endsWith("/en/")) {
    locale = "en";
  } else {
    locale = "ko";
  }

  const popup = (title: string, locale = 'ko') => {
    let windowObj = window.open('', '', 'width=600, height=800, status=no, resizable=no, toolbar=no, left=100, top=100, location=no, menubar=no');
    windowObj?.document.writeln(ArchivePolicy[locale]);
    windowObj?.document.close();
    windowObj?.focus();
  }

  return locale === "en" ? (
    <>
      <div className="content policy">
        <h2>[Adapter Wallet Personal Information Processing Policy]</h2>
        <pre>{`
It is about the management and operation of personal information of users who agree to the personal information processing policy provided by Sinest Co., Ltd. (hereinafter referred to as "company") and use the adapter wallet service (hereinafter referred to as "service").

The company considers users' personal information very important, complies with the relevant laws and regulations of information and communication service providers, such as the Information and Communication Network Promotion and Information Protection Act, and does its best to protect users' rights and interests. The company's service privacy policy is as follows.

The company discloses this personal information processing policy on the site so that users can easily check it at any time.

This personal information processing policy may be changed in accordance with relevant laws and internal policies of the company, and when revised, the revision can be easily checked through version management.





Article 1 (Personal information collected by the company)

A. Items of personal information to be collected
  
  (1) Membership registration and management
    
    - [Required] : Email, encrypted password
    
    - [Collection target]: Member, Contact Service Inquirer

  (2) Information automatically generated and collected during service use

    - [Required]: Encrypted wallet address, encrypted PIN, service usage record, access log, transaction record, IP information, cookies, OS information, language and country information

    - [Selections]: Notification (push app, etc.)

    - [Collection target]: All users (members, non-members)

B. How to collect personal information

  - Enter user's information through membership registration and inquiry

C. How to keep personal information

  - The company stores the user's personal information on the cloud server.





Article 2 (Purpose of Collection and Use of Personal Information)

The company collects only the minimum personal information of users for the following purposes, and the collected personal information is not used for any purpose other than the following purposes. If the purpose of use is changed, necessary measures will be implemented, such as obtaining separate consent under Article 17 of the Personal Information Protection Act.

A. Membership registration and management
  
  1) Member identification, customer identification, member information management, and delivery of various notices

  2) Withdrawal Processing through Non-face-to-face Self-Authentication

B. Provision of Services

C. Handling of inquiry

  1) Identification of the complainant and confirmation of complaints

  2) Contact/notification for fact-finding, notification of processing results, etc





Article 3 (Consignment of Personal Information Processing)

The company entrusts foreign personal information to external companies to perform some of the tasks necessary to provide improved services to users. In addition, we manage and supervise entrusted companies so that they do not violate laws related to personal information protection. In addition, the information is based on the time the user confirmed, and if the consignment company or consignment business changes, we will make sure to change the personal information processing policy and notify the user.

A. Contents of entrustment of personal information

  1) Consignee: Amazon Web Services Inc.

  2) Consignment items: Personal information and logs collected during service use

  3) Country of Consignment: Singapore (AWS Singapore Region)

  4) Consignment method: Transfer through information and communication network during service provision process

  5) Personal information retention and use period: retention during the user's service subscription period





Article 4  (Period of retention and use of personal information)

In principle, personal information is destroyed without delay after the purpose of collecting and using personal information is achieved. However, the following information will be retained for the period specified for the following reasons:

Act on Consumer Protection in Electronic Commerce, etc
  
  - Records of contract or withdrawal of subscription, etc.: 5 years
  
  - Records of consumer complaints or disputes: 3 years

Electronic Financial Transactions Act

  - Records on electronic financial transactions: 5 years

Communications Secrets Protection

  - Login History: 3 Months





Article 5 (Separate storage of personal information of long-term unused members)

A. The company considers a member who has not used the adapter wallet service for more than one year as a dormant member, and separately stores the personal information of the dormant member in a separate storage device in accordance with Article 39-6 of the Personal Information Protection Act and Article 48-5 of the Enforcement Decree of the same Act.

B. The company notifies members of the fact that personal information is separately stored one month before the dormant transition, the scheduled dormant date, and the items of personal information stored separately through registered e-mail.





Article 6 (Procedure and method of destruction of personal information)

In principle, after the purpose of collecting and using personal information is achieved, the company destroys the information without delay. Destruction procedures and methods are as follows.

A. Destruction procedure

Information collected for service use is transferred to a separate database (DB for paper), stored for a certain period of time according to internal policy and other relevant laws (see retention and use period), and personal information transferred to a separate DB is not used for any purpose other than prescribed by law.

B. Method of destruction

  1) Personal information stored in the form of electronic files is deleted using a technical method that cannot be played back.

  2) The personal information printed on the paper is destroyed by grinding or incineration.





Article 7 (Provision of personal information processing)

In principle, the company does not provide users' personal information to the outside world. However, exceptions are made in the following cases.

A. In case of prior consent

B. Where a request is made by an investigative agency in accordance with the provisions of laws and regulations or in accordance with the procedures and methods prescribed by





Article 8 (Rights and obligations of users and legal representatives and methods of exercise)

A. The user or the legal representative of the user may exercise the right to view, correct, delete, or suspend processing of personal information to the company at any time. The user or legal representative may request withdrawal of consent or withdrawal of membership if he or she does not agree to the company's personal information processing. However, in this case, some or all of the services may be difficult to use.

B. Only if it is difficult for the user to exercise his/her rights (correcting or deleting personal information (withdrawing membership) due to unavoidable reasons, he/she may request correction or deletion through e-mail, and the company will take action without delay.

C. If a user or his legal representative requests access, certification, or correction of personal information, respond sincerely to the user's request, and if it is deemed necessary to correct or delete personal information, such as an error or a lapse of preservation period, take action without delay.

D. The exercise of the user's rights can be done through the user's legal representative or delegated person.In this case, you must submit a power of attorney delegated to you.

E. When requesting correction or deletion of personal information, it cannot be requested to delete personal information if it is specified in other laws and regulations.

F. The company checks whether the person who made the request for perusal, correction, or deletion according to the user's right to use or suspension of processing is the person who made the request for perusal, etc.

G. Personal information terminated, deleted, or corrected at the request of a user or legal representative is processed in accordance with Article 4 "Personal Information Retention and Use Period" and cannot be viewed or used for other purposes.





Article  9 (Matters concerning the installation and operation of cookies that automatically collect personal information and the rejection thereof)

A.	The company uses cookies to store information about customers. Cookies are small amounts of information that a website sends to a customer's computer browser (such as Netscape, Internet Explorer, etc.). When a customer accesses a website, the company's computer can read the contents of cookies in the customer's browser, find additional information about the customer on the customer's computer, and provide the service without entering any additional information, such as the name of the connection.
  
  cookie	Using purpose of cookie 	Validate
  1P_JAR	Used to collect statistical data from websites and track conversion rates.	1 month

B. The company operates cookies for the convenience of users. The information the company collects through cookies is as follows.

Cookie name Cookie usage expiration date

Used to collect statistical data from the 1P_JAR website and track conversion rates.	One month

C. By selecting the Options menu on your web browser, you can allow all cookies, confirm each time you save them, or refuse to save all cookies (in Internet Explorer, you can delete all cookies stored on your PC by simply pressing the Delete button under Tools > Internet Options > Search History). Alternatively, if you check the "Delete search history when shutting down" item, cookies can be automatically deleted each time you shut down the browser) However, if you refuse to install cookies, there may be restrictions on providing services such as convenience functions.





Article 10 (Technical and administrative protection measures for personal information)

The company is taking the following technical, administrative, and physical measures to ensure safety so that personal information is not lost, stolen, leaked, altered, or damaged in processing users' personal information.

A. Minimize and educate employees handling personal information

  - We minimize the designation of personal information handlers, issue user accounts for each handler, and provide regular training.

B. Conducting a regular self-audit

  - We regularly conduct self-audits to ensure the stability of personal information processing.

C. Establishment and implementation of an internal management plan

  - An internal management plan is established and implemented for the safe processing of personal information.

D. Encryption of personal information

  - The user's personal information is stored and managed by encrypting the password, so only the user can know it, encrypting file and transmission data, and protecting important data through separate security functions.

E. Technical countermeasures against hacking, etc.

  - In order to prevent personal information leakage and damage caused by hacking or computer viruses, security programs are installed, regularly updated and inspected, and systems are installed in areas with controlled access from the outside, technically/physically monitored and blocked. In addition, we are preparing response procedures for disasters/disasters such as fires, floods, and power outages.
  
  - In addition, we are trying to minimize personal information leakage by applying Internet blocking (network separation) to personal information processing system operator PCs.

F. Restricting access to personal information

  - Password preparation rules are established/applied to record the details of granting, changing, and canceling access to the personal information processing system, and to set and implement safe passwords.
  
  - In order to limit unauthorized access to the personal information processing system, intrusion prevention systems are used, and vulnerability checks are performed more than once a year. In addition, access to the system is automatically blocked if the personal information handler does not process work for a certain period of time or more.

G. Storage of access records and prevention of forgery

  - Access records are kept and inspected in accordance with relevant laws and regulations. 

H. Using a lock for document security

  - Documents containing personal information and auxiliary storage media are stored in a safe place with a lock.

I. Access control for unauthorized persons





Article 11 (Personal Information Protection Officer and Personal Information Protection Officer)

We set up and operate access control procedures for the physical storage place where personal information is stored separately.

the person in charge of personal information protection

  - Name: Kim Il-hoe

  - Email: help@sinest.co.kr

Person in charge of privacy

  - Name: Jang Kang-ho

  - Email: support@sinest.co.kr





Article 12 (How to remedy infringement of rights)

If you need damage relief or counseling for personal information infringement, please refer to the institution below.

Personal Information Infringement Reporting Center (operated by the Korea Internet & Security Agency)

  - Homepage: privacy.kisa.or.kr 

  - Phone number: 118 (without country code)

Personal Information Dispute Mediation Committee (operated by the Korea Internet & Security Agency)

  - Homepage: kopico.go.kr

  - Phone number: (without country code) 1833-6972

Cyber Crime Investigation Team of the Supreme Prosecutors' Office

  - Homepage: spo.go.kr

  - Phone number: 1301 (without country code)

National Police Agency Cybercrime Investigation Team

  - Homepage: ecrm.police.go.kr

  - Phone number: 182 (without country code)





Article 13 (Change of Personal Information Processing Policy)

The personal information processing policy will be applied from the enforcement date, and if there is an addition, deletion, or correction of the contents in accordance with laws and policies, we will notify you of the changes seven days before the enforcement.





Supplementary Provisions
This personal information processing policy will be applied from January 13, 2023.




        `}</pre>
        <div>
          <p>[Archive]</p>
          <a
            href="#!"
            style={{ textDecorationLine: "underline" }}
            onClick={() => popup('Archive', 'en')}
          >
            ► Jun 16th, 2022 ~ Jan 13th, 2023
          </a>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="content policy">
        <h2>[어댑터월렛 개인정보처리방침]</h2>
        <pre>{`

㈜시니스트 (이하 ‘회사’라 한다)가 제공하는 개인정보처리방침에 동의하고 어댑터월렛 서비스(이하 ‘서비스’라 한다)를 이용하는 이용자의 개인정보에 대한 관리 및 운영에 관한 내용입니다.

회사는 이용자의 개인정보를 매우 중요하게 생각하며, 개인정보 보호법 및 개인정보 보호법 시행령 등 정보통신서비스 제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 회사의 서비스 개인정보처리방침은 다음과 같습니다.

회사는 본 개인정보처리방침을 사이트에 공개함으로써 이용자가 언제든지 쉽게 확인할 수 있도록 하고 있습니다.

본 개인정보처리방침은 관계 법령 및 회사의 내부 방침에 따라 변경될 수 있으며, 개정 시 버전 관리를 통하여 개정사항을 쉽게 확인할 수 있도록 하고 있습니다.





제 1조 (회사가 수집하고 있는 개인정보)

가. 수집하는 개인정보의 항목

(1) 회원가입 및 관리

  - [필수항목] : E-mail, 암호화된 비밀번호

  - [수집대상] : 회원, 문의하기 서비스 문의자

(2) 서비스 이용 과정에서 자동 생성・수집되는 정보

  - [필수항목] : 암호화된 지갑주소, 암호화된 PIN번호, 서비스 이용 기록, 접속 로그, 거래기록, IP 정보, 쿠키, OS정보, 언어 및 국가정보

  - [선택항목] : 알림(앱 푸시 등)

  - [수집대상] : 모든 이용자(회원, 비회원)

나. 개인정보 수집방법

  - 회원가입 및 문의하기를 통한 이용자의 정보 입력

다. 개인정보 보관방법

  - 회사는 이용자의 개인정보를 클라우드 서버에 저장, 보관합니다.





제2조(개인정보의 수집 및 이용목적)

회사는 다음의 목적을 위해 이용자의 최소한의 개인정보만을 수집하며, 수집하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않습니다. 이용 목적이 변경되는 경우에는 개인정보 보호법 제17조(동의를 받는 방법)에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.

가. 회원가입 및 관리

  1) 회원 식별, 고객확인, 회원 정보 관리, 각종 고지사항 전달

  2) 비대면 본인 인증을 통한 탈퇴 처리 등

나. 서비스 제공

다. 고충 처리

  1) 민원인의 신원 및 민원사항 확인

  2) 사실조사를 위한 연락∙통지, 처리결과 통보 등





제 3조 (개인정보 처리 위탁)

회사는 이용자에게 향상된 서비스를 제공하기위해 필요한 업무 중 일부를 외부 업체로 하여금 수행하도록 국외에 개인정보를 위탁하고 있습니다. 그리고 위탁 받은 업체가 개인정보보호 관계 법령을 위반하지 않도록 관리·감독하고 있습니다. 또한 해당 정보는 이용자가 확인한 시점을 기준으로 한 정보이며, 위탁 업체 또는 위탁 업무가 변경되는 경우 반드시 개인정보처리방침 변경 및 이용자에게 고지하도록 하겠습니다.

가. 개인정보 위탁 내용

  1) 위탁업체 : Amazon Web Services Inc.

  2) 위탁항목 : 서비스 이용 중 수집되는 개인정보 및 로그

  3) 위탁국가 : 싱가포르(AWS 싱가포르 리젼)

  4) 위탁방법 : 서비스 제공 과정 중 정보통신망을 통해 이전

  5) 개인정보 보유 및 이용기간 : 이용자의 서비스 가입기간 동안 보유





제 4조 (개인정보의 보유 및 이용기간)

개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성된 후 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

전자상거래 등에서의 소비자보호에 관한 법률

  - 계약 또는 청약철회 등에 관한 기록: 5년

  - 소비자의 불만 또는 분쟁처리에 관한 기록: 3년

전자금융거래법

  - 전자금융 거래에 관한 기록: 5년

통신비밀보호법

  - 로그인 기록: 3개월





제 5조 (장기 미이용 회원 개인정보 분리 보관)

  가. 회사는 어댑터월렛 서비스를 1년 이상 이용하지 않은 회원을 휴면회원으로 간주하고, 개인정보보호법 제39조의6 및 동법 시행령 제48조의5에 의거하여 휴면 회원의 개인정보를 별도의 보관장치에 분리 보관합니다.

  나. 회사는 휴면 예정 회원에게 휴면 전환 1개월 전까지 개인정보가 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 등록된 E-mail을 통해 회원에게 알립니다.





제 6조 (개인정보의 파기절차 및 방법)

회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기 절차 및 방법은 다음과 같습니다.

가. 파기절차
  서비스 이용을 위해 수집된 정보는 목적이 달성된 후 별도의 데이터베이스(DB)로 옮겨져(종이의 경우 별도의 서류함), 내부 방침 및 기타 관계 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되며, 별도 DB로 옮겨진 개인정보는 반드시 법률에 의하지 않는 한 명시된 보유목적 이외 다른 목적으로 이용되지 않습니다.

나. 파기방법

  1) 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

  2) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.





제 7조 (개인정보처리의 제공)

회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우는 예외로 합니다.

가. 사전에 동의한 경우

나. 법령의 규정에 의거하거나, 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우





제 8조 (이용자 및 법정대리인의 권리・의무와 행사 방법)

가. 이용자 또는 이용자의 법정대리인은 회사에 언제든지 개인정보 열람・정정・삭제・처리 정지 요구 등의 권리를 행사할 수 있습니다. 이용자 또는 법정 대리인은 회사의 개인정보처리에 동의하지 않는 경우 동의 철회 혹은 회원 탈퇴를 요청할 수 있습니다. 단, 이 경우 서비스의 일부 또는 전부의 이용이 어려울 수 있습니다.

나. 불가피한 사유로 이용자의 권리 행사(개인정보의 정정, 삭제(회원탈퇴))가 어려운 경우에 한해, 이메일 등을 통하여 정정, 삭제를 요청하실 수 있으며, 회사는 지체없이 조치합니다.

다. 이용자 또는 이용자의 법정대리인이 개인정보에 대한 열람•증명 또는 정정을 요청하는 경우에는 이용자의 요청에 성실하게 대응하며, 개인정보에 오류가 있거나 보존기간을 경과한 것이 판명되는 등 정정 또는 삭제할 필요가 있다고 인정되는 경우, 지체 없이 조치합니다.

라. 이용자의 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통해서 할 수 있습니다. 이 경우, 이용자의 권리를 위임받은 위임장을 제출해야 합니다.

마. 개인정보의 정정 및 삭제 요구 시 다른 법령에서 그 개인정보가 수집 대상으로 명시된 경우에는그 삭제를 요구할 수 없습니다.

바. 회사는 이용자의 이용 권리에 따른 열람의 요구・정정・삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.

사. 이용자 또는 법정대리인의 요청에 의해 해지 또는 삭제, 정정된 개인정보는 제 3조 "개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리되고, 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.





제 9조 (개인정보의 자동 수집하는 쿠키(cookie)의 설치·운영 및 그 거부에 관한 사항)

가. 회사는 고객에 대한 정보를 저장하는 쿠키(cookie)를 사용합니다. 쿠키는 웹사이트가 고객의 컴퓨터 브라우저(넷스케이프, 인터넷 익스플로러 등)로 전송하는 소량의 정보입니다. 고객께서 웹사이트에 접속을 하면 회사의 컴퓨터는 고객의 브라우저에 있는 쿠키의 내용을 읽고, 고객의 추가정보를 고객의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.

나. 회사는 이용자의 편의를 위하여 쿠키를 운영합니다. 회사가 쿠키를 통해 수집하는 정보는 아래와 같습니다.

  cookie명	cookie 사용용도	유효기간
  1P_JAR	웹사이트의 통계 데이터를 수집하여 전환율을 추적하기 위해 사용됩니다.	1개월

다. 고객이 사용하는 웹브라우저의 「옵션」메뉴를 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. (인터넷 익스플로러에서는 “도구 > 인터넷 옵션 > 검색기록”에서 삭제버튼을 누르는 것 만으로 이용자 PC에 저장된 쿠키를 모두 삭제할 수 있음. 또는 ‘종료할때 검색 기록 삭제’ 항목을 체크해 놓으면 브라우저를 종료할 때마다 자동으로 쿠키를 삭제할 수 있음) 단, 쿠키설치를 거부하였을 경우 편의 기능 등 서비스 제공에 제한이 있을 수 있습니다.





제 10조 (개인정보의 기술적・관리적 보호 대책)

회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적・관리적・물리적 대책을 강구하고 있습니다.

가. 개인정보 취급 직원의 최소화 및 교육

  - 개인정보취급자의 지정을 최소화하고 취급자 별로 사용자 계정을 발급하며, 정기적인 교육을 시행하고 있습니다.

나. 정기적인 자체 감사 실시

  - 개인정보 처리 관련 안정성 확보를 위해 정기적으로 자체 감사를 실시하고 있습니다.

다. 내부관리계획의 수립 및 시행

  - 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

라. 개인정보의 암호화

  - 이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 파일 및 전송 데이터를 암호화 및 중요한 데이터는 별도의 보안 기능을 통해 보호되고 있습니다.

마. 해킹 등에 대비한 기술적 대책
  
  - 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신ㆍ점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다. 또한, 화재, 홍수, 단전 등의 재해/재난 발생에 대비한 대응절차를 마련하고 있습니다.
  - 추가적으로 개인정보처리시스템 운영자PC에 대한 인터넷 차단(망분리)을 적용하여 개인정보 유출을 최소화하기 위해 노력하고 있습니다.

바. 개인정보에 대한 접근 제한

  - 개인정보처리시스템에 대한 접근권한의 부여, 변경, 말소 내역을 기록하고 안전한 비밀번호를 설정하여 이행할 수 있도록 비밀번호 작성규칙을 수립/적용하고 있습니다.

  - 개인정보처리시스템에 대한 인가 받지 않은 접근을 제한하기 위해 침입차단시스템 등을 활용하고 있으며, 연 1회 이상 취약점 점검을 수행하고 있습니다. 또한, 개인정보취급자가 일정시간 이상 업무처리를 하지 않은 경우에는 자동으로 시스템 접속이 차단되도록 하고 있습니다.

사. 접속기록의 보관 및 위변조 방지

  - 관련 법령에 따라 접속기록을 보관 및 점검하고 있습니다. 

아. 문서보안을 위한 잠금장치 사용

  - 개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.

자. 비인가자에 대한 출입 통제

  - 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.





제 11조 (개인정보 보호책임자 및 개인정보 보호담당자)

회사는 이용자의 개인정보를 보호하고 개인정보와 관련된 민원을 처리하기 위해 아래와 같이 개인정보 보호책임자 및 개인정보 보호담당자를 지정합니다.

개인정보 보호 책임자

  - 성명 : 김일회

  - 이메일 : help@sinest.co.kr

개인정보 보호 담당자

  - 성명 : 장강호

  - 이메일 : support@sinest.co.kr





제 12조 (권익침해 구제방법)

개인정보 침해에 대한 피해 구제 ・ 상담 등이 필요한 경우 아래 기관을 참고하시기 바랍니다.

개인정보 침해신고 센터 (한국인터넷진흥원 운영)
  
  - 홈페이지 : privacy.kisa.or.kr 

  - 전화번호 : (국번없이) 118

개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)
  
  - 홈페이지 : kopico.go.kr
  
  - 전화번호 : (국번없이) 1833-6972

대검찰청 사이버범죄수사단

  - 홈페이지 : spo.go.kr

  - 전화번호 : (국번없이) 1301

경찰청 사이버범죄수사단

  - 홈페이지 : ecrm.police.go.kr

  - 전화번호 : (국번없이)182





제 13조 (개인정보처리방침 변경)

해당 개인정보처리방침은 시행일로부터 적용되며, 법령 ・ 방침에 따른 내용 추가・삭제・정정이 있는 경우 변경사항에 대해 시행 7일전에 메일, 공지사항 등을 통해 알릴 것입니다.





부칙

본 개인정보처리방침은 2023년 1월 13일부터 적용됩니다.




        `}</pre>
        <div>
          <p>[이전 개인정보처리방침 조회]</p>
          <a
            href="#!"
            style={{ textDecorationLine: "underline" }}
            onClick={() => popup('이전 개인정보처리방침')}
          >
            ► 2022-06-16 ~ 2023-01-13 조회
          </a>
        </div>
      </div>
    </>
  );
};

export default Policy;
