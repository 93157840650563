import React from "react";
import { useLocation } from "react-router-dom";

import BoardTop from "../Components/board_top";

const Info = () => {
  const location = useLocation();

  let locale = "ko";
  if (location.pathname.endsWith("/en") || location.pathname.endsWith("/en/")) {
    locale = "en";
  } else {
    locale = "ko";
  }

  const image = "/wallet/img/sub_v_inq_2x.png";

  return locale === "en" ? (
    <>
      <div className="sub">
        <BoardTop
          locale={locale}
          class={"subVsImg inqImg inqImg2x"}
          image={image}
        />
        <div className="inq subCont">
          <div className="subContIn">
            <div className="sContTit">
              <h4>Contact Us</h4>

              <p>
                <span>*</span> This is a required entry. Answers to your inquiry
                will be emailed.
              </p>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="sub">
        <BoardTop
          locale={locale}
          class={"subVsImg inqImg inqImg2x"}
          image={image}
        />
        <div className="inq subCont">
          <div className="subContIn">
            <div className="sContTit">
              <h5>Contact Us</h5>

              <h4>문의하기</h4>

              <p>
                <span>*</span> 필수입력항목 입니다. 문의에 대한 답변은 이메일로
                드립니다.
              </p>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
