import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

interface Notice {
  pk: number;
  title: string;
  time: string;
}

interface Pagenation {
  total: number;
  limit: number;
  page: number;
  prev: number | boolean;
  next: number | boolean;
  setPage: any;
  setPrev: any;
  setNext: any;
}

const MakeList = (prop: any) => {
  const link = `/noti_detail${prop.locale}?pk=${prop.pk}&cur=${prop.page}`;

  return (
    <ul>
      <Link to={link}>
        <li>{prop.title}</li>
      </Link>
      <p>{prop.time}</p>
    </ul>
  );
};

const PageNation = (prop: Pagenation) => {
  const numPage = Math.ceil(prop.total / prop.limit);

  return (
    <>
      <div className="pagingWrap" id="pagingWrap">
        <div className="paging">
          <p className="prev">
            {prop.prev ? (
              <a
                href="#!"
                onClick={() => {
                  prop.setPage(prop.page - 1);
                  prop.setPrev(prop.page - 2 > 0);
                  prop.setNext(prop.page > 0);
                }}
              >
                <img src="/wallet/img/paging_arow.png" alt="" />
              </a>
            ) : (
              <img src="/wallet/img/paging_arow.png" alt="" />
            )}
          </p>
          <ul>
            {Array(numPage)
              .fill(0)
              .map((_, i) => (
                <a
                  href="#!"
                  key={i + 1}
                  onClick={() => {
                    prop.setPage(i + 1);
                    prop.setPrev(i > 0);
                    prop.setNext(i + 1 < numPage);
                  }}
                >
                  <li className={prop.page === i + 1 ? "on" : ""}>{i + 1}</li>
                </a>
              ))}
          </ul>
          <p className="next">
            {prop.next ? (
              <a
                href="#!"
                onClick={() => {
                  prop.setPrev(prop.page - 1 > 0);
                  prop.setPage(prop.page + 1);
                  prop.setNext(prop.page + 1 < numPage);
                }}
              >
                <img src="/wallet/img/paging_arow.png" alt="" />
              </a>
            ) : (
              <img src="/wallet/img/paging_arow.png" alt="" />
            )}
          </p>
        </div>
      </div>
    </>
  );
};

const NoticeList = (prop: any) => {
  const location = useLocation();
  const curPage = new URLSearchParams(location.search).get("page");

  const [posts, setPosts] = useState([]);
  const limit = 5;
  const [page, setPage] = useState(curPage ? +curPage : 1);
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const offset = (page - 1) * limit;

  const locale = prop.locale === "en" ? "/en" : "";

  useEffect(() => {
    const ax = async () => {
      const resAx = await axios.get(
        "https://version.adappterwallet.io:20443/noti" + locale
      );
      const resData = resAx.data;
      setPosts(resData.data);
      setPrev(resData.prev);
      setNext(resData.next);
    };

    ax();
  }, []);

  const resList = posts
    .slice(offset, offset + limit)
    .map((it: Notice) => (
      <MakeList
        key={it.pk}
        pk={it.pk}
        title={it.title}
        time={it.time}
        locale={locale}
        page={page}
      />
    ));

  return (
    <>
      <div className="notiList notiEllip" id="notiWrap">
        {resList}
      </div>
      <PageNation
        total={posts.length}
        limit={limit}
        page={page}
        prev={prev}
        next={next}
        setPage={setPage}
        setPrev={setPrev}
        setNext={setNext}
      />
    </>
  );
};

export default NoticeList;
