import React from "react";
import { useLocation } from "react-router-dom";

import BoardTop from "../Components/board_top";

import NoticeList from "../List/NoticeList";

const Notice = () => {
  const location = useLocation();

  let locale = "ko";
  if (location.pathname.endsWith("/en") || location.pathname.endsWith("/en/")) {
    locale = "en";
  } else {
    locale = "ko";
  }

  const image = "/wallet/img/sub_v_noti_2x.png";

  return locale === "en" ? (
    <>
      <div className="sub">
        <BoardTop
          locale={locale}
          class={"subVsImg notiImg notiImg2x"}
          image={image}
        />
        <div className="noti subCont">
          <div className="subContIn">
            <div className="sContTit">
              <h4>Notice</h4>
            </div>
            <NoticeList locale={locale} />
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="sub">
        <BoardTop
          locale={locale}
          class={"subVsImg notiImg notiImg2x"}
          image={image}
        />
        <div className="noti subCont">
          <div className="subContIn">
            <div className="sContTit">
              <h5>Notice</h5>

              <h4>공지사항</h4>
            </div>

            <NoticeList locale={locale} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Notice;
