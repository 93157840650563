import React from "react";
import { useLocation } from "react-router-dom";

const Teleport = (props: any) => {
  return  (
    <>
      <p>pn5R8li6dsMwpfPKZI5BEXNeFay5PGnzSTQqAbdIDUU.yfU7wUKnHmoXccUjVZ5R0k2rFnwulYLvBBOKqFAq558</p>
    </>
  )
};

export default Teleport;
