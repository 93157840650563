import React from "react";
import { useLocation } from "react-router-dom";

const Main = (props: any) => {
  const location = useLocation();

  let locale = "ko";

  if (location.pathname.endsWith("/en") || location.pathname.endsWith("/en/")) {
    locale = "en";
  } else {
    locale = "ko";
  }

  return locale === "en" ? (
    <>
      <div className="content">
        <section className="sectn1">
          <div className="sectn1Bg"></div>
          <div className="contIn">
            <p>The Begin of the Cryptoasset management</p>
            <h3>
              Adappter <strong>Wallet</strong>
            </h3>
            <ul className="btnDownload">
              <li>
                <a
                  href="https://apps.apple.com/us/app/adappter-wallet/id1629560173"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_appleStore_2x_en.png"
                    alt="Googleplay"
                    className="dwnlodAppleStore"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sinest.adappterwallet"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_googlePlay_2x_en.png"
                    alt="Appstore"
                    className="dwnlodGooglePlay"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://adappter-wallet.s3.ap-northeast-2.amazonaws.com/AdappterWallet.apk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_android_2x.png"
                    alt="Android"
                    className="dwnlodAndroid"
                  />
                </a>
              </li>
            </ul>
            <p className="sn1Img sn1Img2x">
              <img src="/wallet/img/mockup_main_2x_en.png" alt="" />
            </p>
          </div>
        </section>
        <section className="sectn2">
          <div className="contIn">
            <h4>
              Support <strong>Multi </strong>Cryptoasset Chains
            </h4>
            <h5>
              Adappter Wallet is a multi-chain wallet that can manage multiple
              Cryptoassets.
              <br />
              We support various chains such as Bitcoin, Ethereum, and Klaytn
              and add more chains through updates.
            </h5>
            <p className="sn2Img sn2Img2x">
              <img src="/wallet/img/mockup_sec2_2x_en.png" alt="" />
            </p>
          </div>
        </section>
        <section className="sectn3">
          <div className="sectn3MbBg"></div>
          <div className="contIn clear">
            <div className="fl">
              <h4>
                Manage your Cryptoassets <br /> in One place
              </h4>
              <h5>
                Check the amount and balances of your assets stored in the
                various wallets by chains. <br className="pc" />
                Easy management of your portfolio with Adappter wallet.
              </h5>
            </div>
            <div className="fr">
              <img
                src="/wallet/img/mockup_sec3_2x_en.png"
                alt=""
                className="sectn3Img2x"
              />
            </div>
          </div>
        </section>
        <section className="sectn4">
          <div className="contIn clear">
            <div className="fr">
              <h4>
                Easy & Accurate
                <br />
                transaction
              </h4>
              <h5>
                Support Auto-setting of the best transaction fee for the chains.
                Easy transfer of your assets with simple UI such as QR codes and
                check the transaction history.
              </h5>
            </div>
            <div className="fl">
              <img
                src="/wallet/img/mockup_sec4_2x_en.png"
                alt=""
                className="sectn3Img2x sectn4Img2x"
              />
            </div>
          </div>
        </section>
        <section className="sectn5">
          <h4>
            All NFTs <strong>in One place</strong>
          </h4>
          <h5>
            Send and receive NFT of various chains in Adappter Wallet. <br />
            Simple manage NFTs that divided in various wallets & exchanges in
            one wallet.
          </h5>
          <p className="sn5Img">
            <img
              src="/wallet/img/bg_nft_2x_en.png"
              alt=""
              className="pc_tb sn5Img2x"
            />
            <img src="/wallet/img/bg_nft_mb_2x_en.png" alt="" className="mb" />
          </p>
        </section>
        <div className="dlRightNow">
          <div className="dlRightNowIn">
            <p>Download NOW!</p>
            <ul className="btnDownload">
              <li>
                <a
                  href="https://apps.apple.com/us/app/adappter-wallet/id1629560173"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_appleStore_2x_en.png"
                    alt="Googleplay"
                    className="dwnlodAppleStore"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sinest.adappterwallet"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_googlePlay_2x_en.png"
                    alt="Appstore"
                    className="dwnlodGooglePlay"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://adappter-wallet.s3.ap-northeast-2.amazonaws.com/AdappterWallet.apk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_android_2x.png"
                    alt="Android"
                    className="dwnlodAndroid"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="content">
        <section className="sectn1">
          <div className="sectn1Bg"></div>
          <div className="contIn">
            <p>가상자산 관리의 시작</p>
            <h3>
              어댑터 <strong>월렛</strong>
            </h3>
            <ul className="btnDownload">
              <li>
                <a
                  href="https://apps.apple.com/us/app/adappter-wallet/id1629560173"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_appleStore_2x.png"
                    alt="Appstore"
                    className="dwnlodAppleStore"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sinest.adappterwallet"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_googlePlay_2x.png"
                    alt="Appstore"
                    className="dwnlodGooglePlay"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://adappter-wallet.s3.ap-northeast-2.amazonaws.com/AdappterWallet.apk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_android_2x.png"
                    alt="Android"
                    className="dwnlodAndroid"
                  />
                </a>
              </li>
            </ul>
            <p className="sn1Img sn1Img2x">
              <img src="/wallet/img/mockup_main_2x.png" alt="" />
            </p>
          </div>
        </section>
        <section className="sectn2">
          <div className="contIn">
            <h4>
              <strong>다양한 종류</strong>의 체인 지원
            </h4>
            <h5>
              어댑터 월렛은 멀티 코인 지갑으로 여러 종류의 자산을 관리할 수
              있습니다.
              <br />
              비트코인, ERC-20, 클레이튼 등 다양한 체인을 지원하며, 업데이트를
              통해 더 많은 체인을 추가하고 있습니다.
            </h5>
            <p className="sn2Img sn2Img2x">
              <img src="/wallet/img/mockup_sec2_2x.png" alt="" />
            </p>
          </div>
        </section>
        <section className="sectn3">
          <div className="sectn3MbBg"></div>
          <div className="contIn clear">
            <div className="fl">
              <h4>
                한눈에 보이는 <br />
                자산관리
              </h4>
              <h5>
                지갑에 나눠진 자산을 종류별로 모아서 보유량, 가격을 한눈에
                확인할 수 있습니다. <br />
                어댑터 월렛으로 자산의 포트폴리오를 간편하게 관리해 보세요.
              </h5>
            </div>
            <div className="fr">
              <img
                src="/wallet/img/mockup_sec3_2x.png"
                alt=""
                className="sectn3Img2x"
              />
            </div>
          </div>
        </section>
        <section className="sectn4">
          <div className="contIn clear">
            <div className="fr">
              <h4>
                간편하고 정확한 <br />
                자산거래
              </h4>
              <h5>
                자산별로 최적의 전송 수수료를 자동으로
                <br className="pcWeb" /> 설정해 제공합니다.
                <br />
                직관적인 UI, QR코드 공유 등으로 간편하게 자산을{" "}
                <br className="pcWeb" />
                주고 받을 수 있으며, 정확한 거래내역까지 확인할 수 있습니다.
              </h5>
            </div>
            <div className="fl">
              <img
                src="/wallet/img/mockup_sec4_2x.png"
                alt=""
                className="sectn3Img2x sectn4Img2x"
              />
            </div>
          </div>
        </section>
        <section className="sectn5">
          <h4>
            내 모든 NFT를 <strong>한번에 모아서</strong>
          </h4>
          <h5>
            어댑터 월렛은 다양한 체인의 NFT를 보내고
            <br className="mb" /> 받을 수 있습니다.
            <br />
            여러 지갑과 거래소 등에 나눠진 NFT를
            <br className="mb" /> 하나의 지갑에서 쉽고, 편리하게 관리해 보세요.
          </h5>
          <p className="sn5Img">
            <img
              src="/wallet/img/bg_nft_2x.png"
              alt=""
              className="pc_tb sn5Img2x"
            />
            <img src="/wallet/img/bg_nft_mb_2x.png" alt="" className="mb" />
          </p>
        </section>
        <div className="dlRightNow">
          <div className="dlRightNowIn">
            <p>
              어댑터 월렛을 <br className="mb" />
              지금 다운받으세요!
            </p>
            <ul className="btnDownload">
              <li>
                <a
                  href="https://apps.apple.com/us/app/adappter-wallet/id1629560173"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_appleStore_2x.png"
                    alt="Googleplay"
                    className="dwnlodAppleStore"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sinest.adappterwallet"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_googlePlay_2x.png"
                    alt="Appstore"
                    className="dwnlodGooglePlay"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://adappter-wallet.s3.ap-northeast-2.amazonaws.com/AdappterWallet.apk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/wallet/img/btn_download_android_2x.png"
                    alt="Android"
                    className="dwnlodAndroid"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
