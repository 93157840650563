export const ArchivePolicy: { [key: string]: string } = {
  ko: `
  <title>[어댑터월렛 개인정보처리방침]</title>
  <div class="content policy" style='width: auto;overflow-x: hidden'>

	<h2>[어댑터월렛 개인정보처리방침]</h2>

	<pre style="white-space: pre-wrap; font-family: 'Noto Sans KR', sans-serif;">

㈜시니스트 (이하 ‘회사’라 한다)가 제공하는 개인정보처리방침에 동의하고 어댑터월렛 서비스(이하 ‘서비스’라 한다)를 이용하는 이용자의 개인정보에 대한 관리 및 운영에 관한 내용입니다.

회사는 이용자의 개인정보를 매우 중요하게 생각하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스 제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 회사의 서비스 개인정보처리방침은 다음과 같습니다.

회사는 본 개인정보처리방침을 사이트에 공개함으로써 이용자가 언제든지 쉽게 확인할 수 있도록 하고 있습니다.

본 개인정보처리방침은 관계 법령 및 회사의 내부 방침에 따라 변경될 수 있으며, 개정 시 버전 관리를 통하여 개정사항을 쉽게 확인할 수 있도록 하고 있습니다.





제 1조 (회사가 수집하고 있는 개인정보)

수집하는 개인정보의 항목

(1) 회원가입 및 관리

  - [필수항목] : E-mail, 암호화된 비밀번호

  - [수집대상] : 회원, 문의하기 서비스 문의자

(2) 서비스 이용 과정에서 자동 생성・수집되는 정보

  - [필수항목] : 암호화된 지갑주소, 암호화된 PIN번호, 서비스 이용 기록, 접속 로그, 거래기록, IP 정보, 쿠키, OS정보, 언어 및 국가정보

  - [선택항목] : 알림(앱 푸시 등)

  - [수집대상] : 모든 이용자(회원, 비회원)

개인정보 수집방법

  - 회원가입 및 문의하기를 통한 이용자의 정보 입력

개인정보 보관방법

  - 회사는 이용자의 개인정보를 oo에 위치한 oo 클라우드 서버에 저장, 보관합니다.





제2조(개인정보의 수집 및 이용목적)

회사는 다음의 목적을 위해 이용자의 최소한의 개인정보만을 수집하며, 수집하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않습니다. 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.



가. 회원가입 및 관리

  1) 회원 식별, 고객확인, 회원 정보 관리, 각종 고지사항 전달

  2) 비대면 본인 인증을 통한 탈퇴 처리 등

나. 서비스 제공

다. 고충 처리

  1) 민원인의 신원 및 민원사항 확인

  2) 사실조사를 위한 연락∙통지, 처리결과 통보 등





제 3조 (개인정보의 보유 및 이용기간)

개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성된 후 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

전자상거래 등에서의 소비자보호에 관한 법률

  - 계약 또는 청약철회 등에 관한 기록: 5년

  - 소비자의 불만 또는 분쟁처리에 관한 기록: 3년

전자금융거래법

전자금융 거래에 관한 기록: 5년

통신비밀보호법

로그인 기록: 3개월





제 4조 (장기 미이용 회원 개인정보 분리 보관)

가. 회사는 어댑터월렛 서비스를 1년 이상 이용하지 않은 회원을 휴면회원으로 간주하고, 개인정보보호법 제39조의6 및 동법 시행령 제48조의5에 의거하여 휴면 회원의 개인정보를 별도의 보관장치에 분리 보관합니다.

나. 회사는 휴면 예정 회원에게 휴면 전환 1개월 전까지 개인정보가 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 등록된 E-mail을 통해 회원에게 알립니다.





제 5조 (개인정보의 파기절차 및 방법)

회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기 절차 및 방법은 다음과 같습니다.



가. 파기절차

서비스 이용을 위해 수집된 정보는 목적이 달성된 후 별도의 데이터베이스(DB)로 옮겨져(종이의 경우 별도의 서류함), 내부 방침 및 기타 관계 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되며, 별도 DB로 옮겨진 개인정보는 반드시 법률에 의하지 않는 한 명시된 보유목적 이외 다른 목적으로 이용되지 않습니다.

나. 파기방법

  1) 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.

  2) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.





제 6조 (개인정보처리의 제공)

회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우는 예외로 합니다.

  가. 사전에 동의한 경우

  나. 법령의 규정에 의거하거나, 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우





제 7조 (이용자 및 법정대리인의 권리・의무와 행사 방법)

가. 이용자 또는 이용자의 법정대리인은 회사에 언제든지 개인정보 열람・정정・삭제・처리 정지 요구 등의 권리를 행사할 수 있습니다. 이용자 또는 법정 대리인은 회사의 개인정보처리에 동의하지 않는 경우 동의 철회 혹은 회원 탈퇴를 요청할 수 있습니다. 단, 이 경우 서비스의 일부 또는 전부의 이용이 어려울 수 있습니다.

나. 불가피한 사유로 이용자의 권리 행사(개인정보의 정정, 삭제(회원탈퇴))가 어려운 경우에 한해, 이메일 등을 통하여 정정, 삭제를 요청하실 수 있으며, 회사는 지체없이 조치합니다.

다. 이용자 또는 이용자의 법정대리인이 개인정보에 대한 열람•증명 또는 정정을 요청하는 경우에는 이용자의 요청에 성실하게 대응하며, 개인정보에 오류가 있거나 보존기간을 경과한 것이 판명되는 등 정정 또는 삭제할 필요가 있다고 인정되는 경우, 지체 없이 조치합니다.

라. 이용자의 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통해서 할 수 있습니다. 이 경우, 이용자의 권리를 위임받은 위임장을 제출해야 합니다.

마. 개인정보의 정정 및 삭제 요구 시 다른 법령에서 그 개인정보가 수집 대상으로 명시된 경우에는 그 삭제를 요구할 수 없습니다.

바. 회사는 이용자의 이용 권리에 따른 열람의 요구・정정・삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.

사. 이용자 또는 법정대리인의 요청에 의해 해지 또는 삭제, 정정된 개인정보는 제 4조 "개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리되고, 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.





제 8조 (개인정보의 기술적・관리적 보호 대책)

회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적・관리적 대책을 강구하고 있습니다.



가. 개인정보 암호화

이용자의 개인정보는 암호화되어 저장 및 관리되고 있습니다. 비밀번호, PIN번호 및 지갑주소의 경우 회사에서 보관하지 않으며, 본인만 알 수 있고, 개인정보의 환경 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

나. 해킹 등에 대비한 대책

회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.

개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.

다. 처리직원의 최소화 및 교육

회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.

라. 개인정보처리시스템 접근 제한

회사는 개인정보를 처리할 수 있도록 체계적으로 구성한 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소 등에 관한 기준을 수립하고 비밀번호의 생성 방법, 변경 주기 등을 규정 운영하며 기타 개인정보에 대한 접근통제를 위해 필요한 조치를 다하고 있습니다.

마. 개인 E-mail과 비밀번호 관리

회원이 사용하는 E-mail과 비밀번호는 원칙적으로 회원만이 사용하도록 되어 있습니다. 회사는 회원의 개인적인 부주의로 E-mail, 비밀번호 등 개인정보가 유출되어 발생한 문제와 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다. 비밀번호에 대한 보안 의식을 가지고 비밀번호를 자주 변경하며 개인정보가 유출되지 않도록 각별한 주의를 기울여 주시기 바랍니다.





제 9조 (개인정보 보호책임자 및 개인정보 보호담당자)

회사는 이용자의 개인정보를 보호하고 개인정보와 관련된 민원을 처리하기 위해 아래와 같이 개인정보 보호책임자 및 개인정보 보호담당자를 지정합니다.

개인정보 보호 책임자

- 성명 : 김일회

- 직책 : CPO

- 이메일 : help@adappterwallet.io





제 10조 (권익침해 구제방법)

개인정보 침해에 대한 피해 구제 ・ 상담 등이 필요한 경우 아래 기관을 참고하시기 바랍니다.

개인정보 침해신고 센터 (한국인터넷진흥원 운영)

- 홈페이지 : privacy.kisa.or.kr

- 전화번호 : (국번없이) 118

개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)

- 홈페이지 : kopico.go.kr

- 전화번호 : (국번없이) 1833-6972

대검찰청 사이버범죄수사단

- 홈페이지 : spo.go.kr

- 전화번호 : (국번없이) 1301

경찰청 사이버범죄수사단

- 홈페이지 : cyberbureau.police.go.kr

- 전화번호 : (국번없이)182





제 11조 (개인정보처리방침 변경)

해당 개인정보처리방침은 시행일로부터 적용되며, 법령 ・ 방침에 따른 내용 추가 ・ 삭제 ・ 정정이 있는 경우 변경사항에 대해 시행 7일전에 공지사항 등을 통해 알릴 것입니다.





부칙

본 개인정보처리방침은 2022년 6월 16일부터 적용됩니다.



</pre>

</div>
    `,
  en: `
  <title>[Adapter Wallet Personal Information Processing Policy]</title>
  <div class="content policy" style='width: auto;overflow-x: hidden'>

	<h2>[Adapter Wallet Personal Information Processing Policy]</h2>

	<pre style="white-space: pre-wrap; font-family: 'Noto Sans KR', sans-serif;">
  It is about the management and operation of personal information of users who agree to the personal information processing policy provided by Sinest Co., Ltd. (hereinafter referred to as "company") and use the adapter wallet service (hereinafter referred to as "service").
  The company considers users' personal information very important, complies with the relevant laws and regulations of information and communication service providers, such as the Information and Communication Network Promotion and Information Protection Act, and does its best to protect users' rights and interests. The company's service privacy policy is as follows.
  The company discloses this personal information processing policy on the site so that users can easily check it at any time.
  This personal information processing policy may be changed in accordance with relevant laws and internal policies of the company, and when revised, the revision can be easily checked through version management.
  Article 1 (Personal information collected by the company)
  A. Items of personal information to be collected
  (1) Membership registration and management
  - [Required] : Email, encrypted password
  - [Collection target]: Member, Contact Service Inquirer
  (2) Information automatically generated and collected during service use
  - [Required]: Encrypted wallet address, encrypted PIN number, service usage record, access log, transaction record, IP information, cookies, OS information, language and country information
  - [Selections] : Notification (push app, etc.)
  - [Collection target]: All users (members, non-members)
  B. How to collect personal information
  - Enter user's information through membership registration and inquiry
  C. How to keep personal information
  - The company stores and stores the user's personal information on the o cloud server located in o.

  Article 2 (Purpose of Collection and Use of Personal Information)
  The company collects only the minimum personal information of users for the following purposes, and the collected personal information is not used for any purpose other than the following purposes. If the purpose of use is changed, necessary measures will be implemented, such as obtaining separate consent under Article 18 of the Personal Information Protection Act.
  A. Membership registration and management
  1) Member identification, customer identification, member information management, and delivery of various notices
  2) Withdrawal Processing through Non-face-to-face Self-Authentication
  B. Provision of Services
  C. Handling of grievances
  1) Identification of the complainant and confirmation of complaints
  2) Contact/notification for fact-finding, notification of processing results, etc

  Article 3 (Period of retention and use of personal information)
  In principle, personal information is destroyed without delay after the purpose of collecting and using personal information is achieved. However, the following information will be retained for the period specified for the following reasons:
  Act on Consumer Protection in Electronic Commerce, etc
  - Records of contract or withdrawal of subscription, etc.: 5 years
  - Records of consumer complaints or disputes: 3 years
  Electronic Financial Transactions Act
  Records on electronic financial transactions: 5 years
  Communications Secrets Protection
  Login History: 3 Months

  Article 4 (Separate storage of personal information of long-term unused members)
  A. The company considers a member who has not used the adapter wallet service for more than one year as a dormant member, and separately stores the personal information of the dormant member in a separate storage device in accordance with Article 39-6 of the Personal Information Protection Act and Article 48-5 of the Enforcement Decree of the same Act.
  B. The company notifies members of the fact that personal information is separately stored one month before the dormant transition, the scheduled dormant date, and the items of personal information stored separately through registered e-mail.

  Article 5 (Procedure and method of destruction of personal information)
  In principle, after the purpose of collecting and using personal information is achieved, the company destroys the information without delay. Destruction procedures and methods are as follows.
  A. Destruction procedure
  Information collected for service use is transferred to a separate database (DB for paper), stored for a certain period of time according to internal policy and other relevant laws (see retention and use period), and personal information transferred to a separate DB is not used for any purpose other than prescribed by law.
  B. Method of destruction
  1) Personal information stored in the form of electronic files is deleted using a technical method that cannot be played back.
  2) The personal information printed on the paper is destroyed by grinding or incineration.

  Article 6 (Provision of personal information processing)
  In principle, the company does not provide users' personal information to the outside world. However, exceptions are made in the following cases.
  A. In case of prior consent
  B. Where a request is made by an investigative agency in accordance with the provisions of laws and regulations or in accordance with the procedures and methods prescribed by

  Article 7 (Rights and obligations of users and legal representatives and methods of exercise)
  A. The user or the legal representative of the user may exercise the right to view, correct, delete, or suspend processing of personal information to the company at any time. The user or legal representative may request withdrawal of consent or withdrawal of membership if he or she does not agree to the company's personal information processing. However, in this case, some or all of the services may be difficult to use.
  B. Only if it is difficult for the user to exercise his/her rights (correcting or deleting personal information (withdrawing membership) due to unavoidable reasons, he/she may request correction or deletion through e-mail, and the company will take action without delay.
  C. If a user or his legal representative requests access, certification, or correction of personal information, respond sincerely to the user's request, and if it is deemed necessary to correct or delete personal information, such as an error or a lapse of preservation period, take action without delay.
  D. The exercise of the user's rights can be done through the user's legal representative or delegated person.In this case, you must submit a power of attorney delegated to you.
  E. When requesting correction or deletion of personal information, it cannot be requested to delete personal information if it is specified in other laws and regulations.
  F. The company checks whether the person who made the request for perusal, correction, or deletion according to the user's right to use or suspension of processing is the person who made the request for perusal, etc.
  G. Personal information terminated, deleted, or corrected at the request of a user or legal representative is processed in accordance with Article 4 "Personal Information Retention and Use Period" and cannot be viewed or used for other purposes.

  Article 8 (Technical and administrative protection measures for personal information)
  The company is taking the following technical and management measures to ensure safety so that personal information is not lost, stolen, leaked, tampered with or damaged in processing the user's personal information.
  A. Encryption of personal information
  User's personal information is encrypted, stored, and managed. Passwords, PIN numbers, and wallet addresses are not kept by the company, and only you can know, and the environment and change of personal information are possible only by the person who knows the password.
  B. Countermeasures against hacking, etc
  The company is doing its best to prevent users' personal information from being leaked or damaged by hacking or computer viruses.
  In preparation for personal information damage, data are backed up from time to time, the latest vaccine program is used to prevent leakage or damage of users' personal information, and personal information can be safely transmitted on the network through encrypted communication. In addition, we use intrusion prevention systems to control unauthorized access from outside, and we strive to have all possible technical devices to ensure system security.C. Minimize and train processing staff
  The company's personal information processing staff is limited to the person in charge, and a separate password is assigned to the person in charge and updated regularly, and compliance with the personal information processing policy is always emphasized through frequent training.
  D. Restrictions on access to personal information processing systems
  The company establishes standards for granting, changing, and cancelling access rights to the systematic database system to process personal information, regulates and operates password generation methods and frequency of changes, and takes necessary measures to control access to personal information.
  E. Personal e-mail and password management
  In principle, the e-mail and password used by members are only used by members. The company is not responsible for the problems caused by the leakage of personal information such as e-mail and password due to the member's personal carelessness and the basic dangers of the Internet. Please change your password frequently with a sense of security for your password and pay special attention to prevent personal information from being leaked.

  Article 9 (Personal Information Protection Officer and Personal Information Protection Officer)
  In order to protect the user's personal information and handle complaints related to personal information, the company designates the person in charge of personal information and the person in charge of personal information as follows.
  a person in charge of privacy
  - Name: Lucas Kim
  - Position: CPO
  - Email: help@adappterwallet.io

  Article 10 (Method of remedy for infringement of rights)
  Please refer to the institution below if you need damage relief or counseling for personal information infringement.
  Personal Information Infringement Report Center (operated by the Korea Internet & Security Agency)
  - Home page: privacy.kisa.or.kr
  - Phone number: 118
  Personal Information Dispute Mediation Committee (run by the Korea Internet Agency)
  - Home page: kopico.go.kr
  - Phone number: 1833-6972
  Supreme Prosecutors' Office Cyber Crime Investigation Team
  - Home page: spo.go.kr
  - Phone number: 1301
  the National Police Agency's Cyber Crime Investigation Unit
  - Home page: cyberbureau.police.go.kr
  - Phone number: (without number) 182

  Article 11 (Change of Personal Information Processing Policy)
  The personal information processing policy will be applied from the date of enforcement, and if there is an addition, deletion, or correction in accordance with laws and policies, changes will be notified seven days before the enforcement.

  Supplementary Provisions
  This personal information processing policy will take effect from June 16, 2022.
  </pre>

</div>
    `,
};
