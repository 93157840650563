import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./Components/header";
import Footer from "./Components/footer";

import Main from "./View/main";
import Notice from "./View/Notice";
import NoticeView from "./View/NoticeView";
import Info from "./View/Info";
import Inq from "./View/Inq";
import Policy from "./View/Policy";
import Term from "./View/Term";

import Teleport from './View/Teleport';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Main />}></Route>
          <Route path="/en" element={<Main />}></Route>

          <Route path="/noti" element={<Notice />}></Route>
          <Route path="/noti/en" element={<Notice />}></Route>
          <Route path="/noti_detail" element={<NoticeView />}></Route>
          <Route path="/noti_detail/en" element={<NoticeView />}></Route>

          <Route path="/info" element={<Info />}></Route>
          <Route path="/info/en" element={<Info />}></Route>

          <Route path="/inq" element={<Inq />}></Route>
          <Route path="/inq/en" element={<Inq />}></Route>

          <Route path="/policy" element={<Policy />}></Route>
          <Route path="/policy/en" element={<Policy />}></Route>

          <Route path="/term" element={<Term />}></Route>
          <Route path="/term/en" element={<Term />}></Route>

          <Route path="/.well-known/acme-challenge/pn5R8li6dsMwpfPKZI5BEXNeFay5PGnzSTQqAbdIDUU" element={<Teleport />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
